import { defineMessages } from 'react-intl';

export default defineMessages({
    viewInvoices: {
        id: 'billing.billing-summary.View Invoices',
        defaultMessage: 'View Invoices'
    },
    makeAPayment: {
        id: 'billing.billing-summary.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    viewPolicies: {
        id: 'billing.billing-summary.View Policies',
        defaultMessage: 'View Policies'
    },
    changePaymentMethod: {
        id: 'billing.billing-summary.Change Payment Method',
        defaultMessage: 'Change Payment Method'
    },
    setupAutomaticPayments: {
        id: 'billing.billing-summary.Setup Automatic Payments',
        defaultMessage: 'Setup Automatic Payments'
    },
    selectInvoicesToPay: {
        id: 'billing.billing-summary.Select Invoices to Pay',
        defaultMessage: 'Select Invoices to Pay'
    },
    policies: {
        id: 'billing.billing-summary.Policies',
        defaultMessage: 'Policies'
    },
    payNow: {
        id: 'billing.billing-summary.Pay Now',
        defaultMessage: 'Pay Now'
    },
    personalAutoPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Personal Auto Policy (Automatic Payments Enabled)',
        defaultMessage: 'Personal Auto Policy (Automatic Payments Enabled)'
    },
    personalAutoPolicy: {
        id: 'billing.billing-summary.Personal Auto Policy',
        defaultMessage: 'Personal Auto Policy'
    },
    homeownersPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Homeowners Policy (Automatic Payments Enabled)',
        defaultMessage: 'Homeowners Policy (Automatic Payments Enabled)'
    },
    homeownersPolicy: {
        id: 'billing.billing-summary.Homeowners Policy',
        defaultMessage: 'Homeowners Policy'
    },
    businessOwnersPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Business Owners Policy (Automatic Payments Enabled)',
        defaultMessage: 'Business Owners Policy (Automatic Payments Enabled)'
    },
    businessOwnersPolicy: {
        id: 'billing.billing-summary.Business Owners Policy',
        defaultMessage: 'Business Owners Policy'
    },
    commercialAutoPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Auto Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Auto Policy (Automatic Payments Enabled)'
    },
    commercialAutoPolicy: {
        id: 'billing.billing-summary.Commercial Auto Policy',
        defaultMessage: 'Commercial Auto Policy'
    },
    commercialPropertyPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Property Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Property Policy (Automatic Payments Enabled)'
    },
    commercialPropertyPolicy: {
        id: 'billing.billing-summary.Commercial Property Policy',
        defaultMessage: 'Commercial Property Policy'
    },
    commercialPackagePolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Commercial Package Policy (Automatic Payments Enabled)',
        defaultMessage: 'Commercial Package Policy (Automatic Payments Enabled)'
    },
    commercialPackagePolicy: {
        id: 'billing.billing-summary.Commercial Package Policy',
        defaultMessage: 'Commercial Package Policy'
    },
    generalLiabilityPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.General Liability Policy (Automatic Payments Enabled)',
        defaultMessage: 'General Liability Policy (Automatic Payments Enabled)'
    },
    generalLiabilityPolicy: {
        id: 'billing.billing-summary.General Liability Policy',
        defaultMessage: 'General Liability Policy'
    },
    inlandMarinePolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Inland Marine Policy (Automatic Payments Enabled)',
        defaultMessage: 'Inland Marine Policy (Automatic Payments Enabled)'
    },
    inlandMarinePolicy: {
        id: 'billing.billing-summary.Inland Marine Policy',
        defaultMessage: 'Inland Marine Policy'
    },
    workersCompPolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Workers Compensation Policy (Automatic Payments Enabled)',
        defaultMessage: 'Workers Compensation Policy (Automatic Payments Enabled)'
    },
    workersCompPolicy: {
        id: 'billing.billing-summary.Workers Compensation Policy',
        defaultMessage: 'Workers Compensation Policy'
    },
    workersComp7PolicyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Workers Compensation (v7) Policy (Automatic Payments Enabled)',
        defaultMessage: 'Workers Compensation (v7) Policy (Automatic Payments Enabled)'
    },
    workersComp7Policy: {
        id: 'billing.billing-summary.Workers Compensation (v7) Policy',
        defaultMessage: 'Workers Compensation (v7) Policy'
    },
    policyAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Policy (Automatic Payments Enabled)',
        defaultMessage: 'Policy (Automatic Payments Enabled)'
    },
    policy: {
        id: 'billing.billing-summary.Policy',
        defaultMessage: 'Policy'
    },
    ok: {
        id: 'billing.billing-summary.OK',
        defaultMessage: 'OK'
    },
    invoicesAutomaticPaymentsEnabled: {
        id: 'billing.billing-summary.Invoices (Automatic Payments Enabled)',
        defaultMessage: 'Invoices (Automatic Payments Enabled)'
    },
    paymentSchedule: {
        id: 'billing.billing-summary.Payment Schedule',
        defaultMessage: 'Payment Schedule'
    },
    startingDate: {
        id: 'billing.billing-summary.Starting Date',
        defaultMessage: 'Starting Date'
    },
    paymentMethodHasBeenChanged: {
        id: 'billing.billing-summary.Payment method has been changed.',
        defaultMessage: 'Payment method has been changed.'
    },
    automaticPaymentOfHasBeenAppliedToYourAccount: {
        id: 'billing.billing-summary.Your payment method has been successfully updated to automatic renewal.',
        defaultMessage: 'Your payment method has been successfully updated to automatic renewal.'
    },
    yourAutomaticPaymentsWillBeginOn: {
        id: 'billing.billing-summary.Your automatic payments will begin on x.',
        defaultMessage: 'Your automatic payments will begin on {startingDate}.'
    },
    paymentOfHasBeenAppliedToYourAccount: {
        id: 'billing.billing-summary.Payment of x has been applied to your account.',
        defaultMessage: 'Payment of {paidAmount} has been applied to your account.'
    },
    amountToPay: {
        id: 'billing.billing-summary.Amount to Pay',
        defaultMessage: 'Amount to Pay'
    },
    paymentRequestFailed: {
        id: 'billing.billing-summary.Payment request failed',
        defaultMessage: 'Payment request failed'
    },
    sorryYourPaymentCouldNotBeProcessedAtThisTime: {
        id: 'billing.billing-summary.Sorry your payment could not be processed at this time',
        defaultMessage: 'Sorry your payment could not be processed at this time'
    },
    accountUpdateFailed: {
        id: 'billing.billing-summary.Account update failed',
        defaultMessage: 'Account update failed'
    },
    sorryWeWereNotAbleToSetupAutomaticPaymentsOnYourAccount: {
        id: 'billing.billing-summary.Sorry, we were not able to setup automatic payments on your account',
        defaultMessage: 'Sorry, we were not able to setup automatic payments on your account'
    },
    changingPaymentMethod: {
        id: 'billing.billing-summary.Changing payment method',
        defaultMessage: 'Changing payment method'
    },
    thereWasAProblemChangingThePaymentMethod: {
        id: 'billing.billing-summary.There was a problem changing the payment method',
        defaultMessage: 'There was a problem changing the payment method'
    },
    invoicesToPay: {
        id: 'billing.billing-summary.Invoices to Pay',
        defaultMessage: 'Invoices to Pay'
    },
    paidInvoices: {
        id: 'billing.billing-summary.Paid Invoices',
        defaultMessage: 'Paid Invoices'
    },
    amountToPayErrorMessage: {
        id: 'billing.billing-summary.You Must Pay x Error Message',
        defaultMessage: 'You must pay {amount}'
    },
    leastAmountToPayErrorMessage: {
        id: 'billing.billing-summary.You Must Pay at least x Error Message',
        defaultMessage: 'You must pay at least {amount}'
    },
    acceptTnC: {
        id: 'billing.billing-summary.I Accept The',
        defaultMessage: 'I accept the '
    },
    tAndC: {
        id: 'billing.billing-summary.Terms and Conditions',
        defaultMessage: 'terms and conditions'
    },
    payeezyAttentionMessage: {
        id: 'billing.billing-summary.Payeezy Attention Message',
        defaultMessage: 'ATTENTION: PRIOR TO SUBMITTING A PAYMENT, we strongly recommend that you contact your credit card issuing company. Transactions for high dollar amounts are most likely to be declined. This will keep you from repeating the online payment process.'
    },
    payeezyServiceFeeMessage: {
        id: 'billing.billing-summary.Payeezy Service Fee Message',
        defaultMessage: 'By clicking Confirm you will be charged a service fee of $20.00 for paying by credit card once the payment is processed.'
    },
    payeezyCreditCardPaymentAlternatives: {
        id: 'billing.billing-summary.Payeezy Credit Card Payment Alternatives',
        defaultMessage: 'Alternatives to paying by credit card include cashiers checks, certified checks, money orders, business/personal checks or phone. Contact information below:'
    },
    paymentAlternativesContactInformation: {
        id: 'billing.billing-summary.Payment Alternatives Contact Information',
        defaultMessage: "State Workers' Insurance Fund  \n100 Lackawanna Ave. \nP.O. Box 5100  Scranton, PA 18505-5100 \n(570)-963-4635"
    },
    policyPendingCancellationWarningMessage: {
        id: 'billing.billing-summary.Policy Pending Cancellation Warning Message',
        defaultMessage: 'Making a payment does not guarantee a reinstatement of a pending or cancelled policy.'
    },
    policyDisputedAmountWarningMessage: {
        id: 'billing.billing-summary.Policy Disputed Amount Warning Message',
        defaultMessage: 'This policy has {amount} in dispute as of {currentDate} which is not reflected in the invoice totals below.'
    },
    convertedPolicyWarningMessage: {
        id: 'billing.billing-summary.verbiage Warning Message',
        defaultMessage: 'IMPORTANT: SWIF recently implemented a new computer system. All invoices prior to this policy term will not be reflected on this portal. Prior terms will be invoiced via paper and delivered by USPS. If you received a mailed invoice with a balance due or a credit balance on a previous term, that amount is not included on the portal. If you need to check your current balance, please contact our Customer Service department at 570-963-4635.'
    },
    renewalPolicyWarningMessage: {
        id: 'billing.billing-summary.renewal Warning Message',
        defaultMessage: 'If you wish to renew a policy and are opting to pay by credit card instead of by check, the payment for renewals is not currently available via the portal.  It can be paid by calling SWIF at 570-941-1947.  Note that the balance due is not reflected on the portal but is itemized on the invoice you received in the mail.'
    }
});
