import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import metadata from './UserRegisterMessageComponent.metadata.json5';
import messages from './UserRegisterMessageComponent.messages';
import styles from './UserRegisterMessageComponent.modules.scss';

function UserRegisterMessageComponent(props) {
    const translator = useContext(TranslatorContext);
    const {
        linkTo, header, linkText, onCallback, description
    } = props;

    const overrides = {
        authErrorHeader: {
            content: translator(header)
        },
        authErrorDescription: {
            content: translator(description)
        },
        authErrorLink: {
            content: linkText,
            to: linkTo,
            onClick: onCallback
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleCallback: onCallback
        }
    };

    return (<MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrides}
        {...resolvers}
    />);
}

UserRegisterMessageComponent.propTypes = {
    header: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    description: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    linkText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    linkTo: PropTypes.string,
    onCallback: PropTypes.func
};

UserRegisterMessageComponent.defaultProps = {
    header: undefined,
    description: undefined,
    linkText: messages.backToRegistration,
    onCallback: undefined,
    linkTo: '/auth/register'
};

export default UserRegisterMessageComponent;
