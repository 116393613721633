import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Icon,
    Loader,
    useModal
} from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import infoWindowStaticContent, { infoWindowContent } from '../infoWindow/infoWindowContent';
import { RepairFacilityFullScreenModal } from '../RepairFacilityDetails/RepairFacilityDetails';
import RepairFacilityRating from '../RepairFacilitiesRating/RepairFacilitiesRating';
import styles from './RepairFacilitiesList.module.scss';
import messages from '../RepairFacilitiesMap.messages';

function RepairFacilitiesList(props) {
    const {
        showModal
    } = useModal();

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const [vendorsList, setVendorsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const {
        contactName,
        selectCallback,
        mapCenter,
        goNext,
        lossLocation,
        policyLocation,
        addMarker,
        clearMarkers,
        moveMapToCenter,
        getSearchList,
        updateFacility,
        selectedFacility
    } = props;

    const infoWindowCallback = useCallback((facility) => {
        if (selectCallback) {
            selectCallback(facility);
            updateFacility(facility);
        }
    }, [selectCallback, updateFacility]);

    useEffect(() => {
        setLoading(true);
        const getResults = async () => {
            const results = await getSearchList(mapCenter);
            setVendorsList(results);
            setLoading(false);
        };
        getResults();
    }, [getSearchList, mapCenter, moveMapToCenter]);

    useEffect(() => {
        if (!_.isEmpty(selectedFacility)) {
            const { primaryAddress: { spatialPoint } } = selectedFacility;
            moveMapToCenter(spatialPoint, infoWindowContent(selectedFacility));
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        clearMarkers();
        vendorsList.forEach((facility) => {
            const { primaryAddress: { spatialPoint } } = facility;
            addMarker(spatialPoint, 'wrench', infoWindowContent(facility), infoWindowCallback.bind(null, facility));
        });
        const staticLocations = [{ ...lossLocation, type: 'car' }, { ...policyLocation, type: 'home' }];
        staticLocations.forEach((location) => {
            addMarker(_.get(location, 'geocode'), location.type, infoWindowStaticContent(location));
        });
    }, [addMarker, clearMarkers, lossLocation, policyLocation, vendorsList, infoWindowCallback]);

    const selectVendorHandler = useCallback((evt, item) => {
        evt.preventDefault();
        if (breakpoint === 'phoneWide' || breakpoint === 'phone') {
            const componentProps = {
                showCloseBtn: false,
                showCancelBtn: true,
                repairFacility: item,
                vendorsList: [],
                goNext,
                lossLocation,
                policyLocation
            };
            showModal(
                <RepairFacilityFullScreenModal {...componentProps} />
            );
        }
        const { primaryAddress: { spatialPoint } } = item;
        moveMapToCenter(spatialPoint, infoWindowContent(item));
        selectCallback(item);
        return true;
    }, [
        breakpoint,
        selectCallback,
        moveMapToCenter,
        goNext,
        lossLocation,
        policyLocation
    ]);

    const noResultsOrLoadingContent = useCallback(() => {
        if (loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader loaded={!loading} text={messages.searchingForRepairFacilities} />
                </div>
            );
        }

        if (_.isEmpty(vendorsList)) {
            return (
                <div className={styles.message}>
                    <Icon icon="mi-info" />
                    <p className={styles.desc}>{translator(messages.noRecommendedRepair)}</p>
                    <p className={styles.desc}>{translator(messages.pleaseTryDifferentLocation)}</p>
                </div>
            );
        }
        return true;
    }, [loading, vendorsList, translator]);

    return (
        <div className={styles.addressList}>
            <ul className={styles.addressListContainer}>
                {noResultsOrLoadingContent()}
                {vendorsList.map((vendor) => {
                    return (
                        <li
                            key={vendor.vendorName}
                            className={vendor.contactName === contactName && breakpoint !== 'phone' ? styles.selected : ''}
                            role="presentation"
                            onClick={(evt) => {
                                selectVendorHandler(evt, vendor);
                            }}
                        >
                            <div className={styles.addressListContent}>
                                <div className={styles.lists}>
                                    <div className={styles.listItem}>
                                        <p className={styles.primaryDetail}>{vendor.contactName}</p>
                                        <p className={styles.secondaryDetail}>
                                            <span>{vendor.primaryAddress.addressLine1}</span>
                                            <span className={styles.listIcon}>
                                                <Icon className={styles.checkIcon} icon="mi-check" />
                                                {
                                                    (breakpoint === 'phoneWide' || breakpoint === 'phone')
                                                        && (<Icon className={styles.angleIcon} icon="mi-chevron_right" />)
                                                }
                                            </span>
                                        </p>
                                        <p className={styles.gwShowForPhoneLandscapeDown}>
                                            {vendor.proximateDistance}
                                        </p>
                                        <RepairFacilityRating score={vendor.score} />
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
RepairFacilitiesList.defaultProps = {
    getSearchList: _.noop,
    updateFacility: _.noop,
    selectedFacility: {}
};
RepairFacilitiesList.propTypes = {
    selectCallback: PropTypes.func.isRequired,
    contactName: PropTypes.string.isRequired,
    mapCenter: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired
    }).isRequired,
    goNext: PropTypes.func.isRequired,
    policyLocation: PropTypes.shape({
        geocode: PropTypes.shape({
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired
        })
    }).isRequired,
    lossLocation: PropTypes.shape({
        geocode: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number
        })
    }).isRequired,
    moveMapToCenter: PropTypes.func.isRequired,
    addMarker: PropTypes.func.isRequired,
    clearMarkers: PropTypes.func.isRequired,
    getSearchList: PropTypes.func,
    updateFacility: PropTypes.func,
    selectedFacility: PropTypes.shape({})
};
export default RepairFacilitiesList;
