import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class AccountBillingDetailsService {
    static getPolicyBillingSummary(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPolicyBillingSummary', [policyNumber], additionalHeaders);
    }
    
    static getPaymentInformation(policyNumber, amount, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPaymentInformation', [policyNumber, amount], additionalHeaders);
    }
}
