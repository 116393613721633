export default function (mapsApi, styles) {
    class CustomMarker extends mapsApi.OverlayView {
        constructor(latlng, map, options) {
            super();
            this.latlng = latlng;
            this.options = options || {};
            this.setMap(map);
            this.mapsApi = mapsApi;
        }

        draw() {
            if (!this.div) {
                this.div = document.createElement('div');
                this.div.className = styles.marker;
                this.div.style.background = this.options.backgroundColor;
                this.div.innerHTML = `<i 
                    data-id="${this.options.id}" 
                    class="fa ${styles.icon} 
                    fa-${this.options.icon}"
                />`;

                this.mapsApi.event.addDomListener(this.div, 'click', (event) => {
                    this.mapsApi.event.trigger(this, 'click', event);
                });

                this.mapsApi.event.addDomListener(this.div, 'mouseover', (event) => {
                    this.mapsApi.event.trigger(this, 'mouseover', event);
                });

                this.mapsApi.event.addDomListener(this.div, 'mouseout', (event) => {
                    this.mapsApi.event.trigger(this, 'mouseout', event);
                });

                this.getPanes().overlayImage.appendChild(this.div);
            }

            const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

            if (point) {
                this.div.style.left = `${point.x}px`;
                this.div.style.top = `${point.y}px`;
            }
        }

        remove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                delete this.div;
            }
        }

        getPosition() {
            return this.latlng;
        }
    }

    return CustomMarker;
}
