import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
import { DocumentUploadService } from 'gw-portals-document-js';

export default class PolicyService {
    static getAccountPolicySummaries(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicySummaries', [], authHeader);
    }

    static getAccountPolicyDetails(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getAccountPolicyDetails', [policyNumber], authHeader);
    }

    static removeDocument(publicID, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('removePolicyDocument'), 'removeDocument', [publicID], authHeader);
    }

    static uploadDocument(document, documentMetadata, authHeader = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('uploadPolicyDocument'), document, documentMetadata, authHeader);
    }

    static getDocumentUploadToken(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('generateUploadToken'), 'generateUploadToken', params, additionalHeaders);
    }

    static createPolicyPayrollActivity(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'createPolicyPayrollActivity', [policyNumber], authHeader);
    }

    static getIsUserAdmin(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getIsUserAdmin', [], additionalHeaders);
    }

    /**
    * gets a non admin user from the Database
    *
    * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    */

    static getRegisterUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getRegisterUser', [], additionalHeaders);
    }

    /**
    * Approve a non admin user to the Database
    *
    *  @param {Object} userDTO enrollment data
    * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    */

    static approveRegisterUser(userDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'approveRegisterUser', [userDTO], additionalHeaders);
    }

    /**
    * reject a non admin user from the Database
    *
    *  @param {Object} userDTO enrollment data
    * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    */

    static rejectRegisterUser(userDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'rejectRegisterUser', [userDTO], additionalHeaders);
    }

    static getPolicyPendingCancellationDetails(policyNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'getCancellationStatus', [policyNumber], authHeader);
    }

    static getDocumentDowloadLink(publicID, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('policy'), 'viewDocument', [publicID], additionalHeaders);
    }
}
