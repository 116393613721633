import React, {
    useEffect, useState, useCallback, useContext, useMemo
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Loader, useModal } from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import {
    ViewModelServiceContext,
    withViewModelService,
    ViewModelForm
} from 'gw-portals-viewmodel-react';
import { isCapabilityEnabled } from 'gw-portals-config-js';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { messages as commonMessages } from 'gw-platform-translations';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import UserDetailsComponent from '../../components/UserDetailsComponent/UserDetailsComponent';
import metadata from './UsersProfile.metadata.json5';
import messages from './UsersProfile.messages';
import styles from './UsersProfile.module.scss';

const NOT_EDIT_MODE_INDEX = -1;

const hasPoliciesInForce = (accountInfo) => {
    return accountInfo.relatedPolicies.length > 0;
};

const billingPreferenceValid = (account) => {
    if (account.billingPreference === 'mail') {
        return true;
    }

    let billingEmailAddress;
    const isBillingAddressSame = account.accountSummaryVM.isBillingAddressSame.value;
    if (isBillingAddressSame) {
        billingEmailAddress = account.accountSummaryVM.accountContact.emailAddress1.value;
    } else {
        billingEmailAddress = account.accountSummaryVM.billingContact.emailAddress1.value;
    }
    return account.billingPreference === 'paperless' && billingEmailAddress;
};

const copyContactProperties = (targetAccountContact, accountContact) => {
    return Object.assign({}, targetAccountContact, {
        cellNumber: accountContact.cellNumber,
        primaryPhoneType: accountContact.primaryPhoneType,
        emailAddress1: accountContact.emailAddress1,
        workNumber: accountContact.workNumber,
        homeNumber: accountContact.homeNumber,
        primaryAddress: {
            addressLine1: accountContact.primaryAddress.addressLine1,
            addressLine2: accountContact.primaryAddress.addressLine2,
            addressLine3: accountContact.primaryAddress.addressLine3,
            city: accountContact.primaryAddress.city,
            postalCode: accountContact.primaryAddress.postalCode,
            state: accountContact.primaryAddress.state,
            country: accountContact.primaryAddress.country
        }
    });
};

const addressChangedInForm = (updatedAccount, backUpAccountSummary) => {
    const accountAddressSame = _.isEqual(
        backUpAccountSummary.accountContact,
        updatedAccount.accountSummaryVM.accountContact.value
    );
    const billingAddressSame = _.isEqual(
        backUpAccountSummary.billingContact,
        updatedAccount.accountSummaryVM.billingContact.value
    );
    const billingStatusSame = _.isEqual(
        backUpAccountSummary.isBillingAddressSame,
        updatedAccount.accountSummaryVM.isBillingAddressSame.value
    );
    return !(accountAddressSame && billingAddressSame && billingStatusSame);
};

const billingMethodChangedInForm = (updatedAccount, backupBillingPreference) => {
    return updatedAccount.billingPreference !== backupBillingPreference;
};

function UsersProfile() {
    const {
        showConfirm,
        showAlert
    } = useModal();

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const localeService = ServiceManager.getService('locale-service');
    const { authHeader } = useAuthentication();
    const { onValidate, isComponentValid, disregardFieldValidation } = useValidation(
        'UsersProfile'
    );
    const [displayLoader, updateDisplayLoader] = useState(true);
    const [displayError, updateDisplayError] = useState(false);
    const [editModeState, updateEditModeState] = useState({
        accountInEditIndex: NOT_EDIT_MODE_INDEX
    });
    const [isMultiAccount, updateIsMultiAccount] = useState(false);
    const [accountsDetails, updateAccountsDetails] = useState({});

    useEffect(() => {
        const { capabilitiesConfig } = appConfig;
        const accountInfoPromise = UsersProfileDetailsService.getAccountsContactSummaries(
            authHeader
        );
        let billingDeliveryPromise = Promise.resolve(null);
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            billingDeliveryPromise = AccountBillingDetailsService.getInvoiceDeliveryMethods(
                authHeader
            );
        }

        Promise.all([accountInfoPromise, billingDeliveryPromise])
            .then(([accountResult, billingResult]) => {
                const accountSummaries = _.sortBy(accountResult, 'accountNumber');
                const billingTypeInfo = _.sortBy(billingResult, 'accountNumber');

                const multiAccount = accountSummaries.length > 1;
                updateIsMultiAccount(multiAccount);

                const accountSummariesVM = accountSummaries.map(
                    (accountInfo) => viewModelService.create(
                        accountInfo,
                        'pc',
                        'edge.capabilities.profileinfo.user.dto.AccountSummaryDTO'
                    )
                );
                const accounts = accountSummaries.map((accountSummary, index) => {
                    return {
                        billingPreference: !_.isEmpty(billingTypeInfo) ? billingTypeInfo[index].invoiceDeliveryType : '',
                        accountSummaryVM: accountSummariesVM[index],
                        applyToAllAccounts: false
                    };
                });
                updateAccountsDetails({ accounts });
            })
            .finally(() => updateDisplayLoader(false));
        // Only executed on the first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateAccounts = useCallback((model) => {
        const { accounts: updatedDetails } = model;
        updateAccountsDetails({ accounts: [...updatedDetails] });
    }, []);

    const enterEditMode = useCallback(
        (evt) => {
            const index = parseInt(evt.index, 10);
            const { accounts } = accountsDetails;
            updateEditModeState({
                accountInEditIndex: index,
                backUpAccountSummary: _.cloneDeep(accounts[index].accountSummaryVM.value),
                backupBillingPreference: _.cloneDeep(accounts[index].billingPreference)
            });
        },
        [accountsDetails]
    );

    const resetEditState = useCallback(() => {
        updateEditModeState({
            accountInEditIndex: NOT_EDIT_MODE_INDEX
        });
        updateDisplayError(false);
    }, []);

    const handleCancel = useCallback(() => {
        showConfirm({
            title: messages.cancelEditTitle,
            message: messages.cancelEditMessage,
            status: 'info',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.yesModel,
            cancelButtonText: commonMessages.noModel
        }).then((results) => {
            if (results === 'confirm') {
                const {
                    accountInEditIndex,
                    backUpAccountSummary,
                    backupBillingPreference
                } = editModeState;
                const { accounts } = accountsDetails;
                accounts[accountInEditIndex].accountSummaryVM.value = backUpAccountSummary;
                accounts[accountInEditIndex].billingPreference = backupBillingPreference;
                accounts[accountInEditIndex].applyToAllAccounts = false;
                updateAccounts({ accounts });
                resetEditState();
            }
        }, _.noop);
    }, [accountsDetails, editModeState, resetEditState, showConfirm, updateAccounts]);

    const updateAllAccounts = useCallback(
        (updatedAccount, updatedAccountIndex, allAccounts) => {
            const updateCalls = [];
            const {
                billingPreference: updatedBillingPreference,
                accountSummaryVM: {
                    value: {
                        accountContact: updatedAccountContact,
                        billingContact: updatedBillingContact,
                        isBillingAddressSame: updatedIsBillingAddressSame
                    }
                }
            } = updatedAccount;

            const modifiedAccounts = [];
            allAccounts.forEach((account, index) => {
                let accountToBeUpdated;
                if (updatedAccountIndex !== index) {
                    const newAccount = {
                        accountSummaryVM: viewModelService.clone(account.accountSummaryVM),
                        applyToAllAccounts: false
                    };

                    newAccount.accountSummaryVM.accountContact.value = copyContactProperties(
                        account.accountSummaryVM.accountContact.value,
                        updatedAccountContact
                    );
                    newAccount.accountSummaryVM
                        .isBillingAddressSame.value = updatedIsBillingAddressSame;
                    newAccount.billingPreference = updatedBillingPreference;

                    if (!updatedIsBillingAddressSame) {
                        newAccount.accountSummaryVM.billingContact.value = copyContactProperties(
                            account.accountSummaryVM.billingContact.value,
                            updatedBillingContact
                        );
                    } else {
                        newAccount.accountSummaryVM.billingContact.value = copyContactProperties(
                            account.accountSummaryVM.billingContact.value,
                            updatedAccountContact
                        );
                    }

                    modifiedAccounts.push(newAccount);
                    accountToBeUpdated = newAccount;
                } else {
                    modifiedAccounts.push(updatedAccount);
                    accountToBeUpdated = updatedAccount;
                }

                updateCalls.push(
                    UsersProfileDetailsService.updateAccountContactSummary(
                        accountToBeUpdated.accountSummaryVM.value,
                        authHeader
                    )
                );
                updateCalls.push(
                    AccountBillingDetailsService.setInvoiceDeliveryMethodForAccount(
                        updatedBillingPreference,
                        accountToBeUpdated.accountSummaryVM.value.accountNumber,
                        authHeader
                    )
                );
            });

            Promise.all(updateCalls)
                .then(
                    () => {
                        modifiedAccounts[updatedAccountIndex].applyToAllAccounts = false;
                        updateAccounts({ accounts: modifiedAccounts });
                        resetEditState();
                    },
                    () => {
                        updateDisplayError(true);
                    }
                )
                .finally(() => updateDisplayLoader(false));
        },
        [authHeader, resetEditState, updateAccounts, viewModelService]
    );

    const updateContactDetails = useCallback(() => {
        const { accountInEditIndex, backupBillingPreference, backUpAccountSummary } = editModeState;
        const { accounts } = accountsDetails;
        const updatedAccountDetails = accounts[accountInEditIndex];
        const { capabilitiesConfig } = appConfig;
        const isBillingEnabled = isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' });
        if (isBillingEnabled && !billingPreferenceValid(updatedAccountDetails)) {
            showAlert({
                title: translator(messages.inValidPaperlessTitle),
                message: translator(messages.inValidPaperlessMessage),
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            return;
        }

        updateDisplayLoader(true);
        if (updatedAccountDetails.applyToAllAccounts) {
            updateAllAccounts(updatedAccountDetails, accountInEditIndex, accounts);
            return;
        }

        const updateCalls = [];
        if (addressChangedInForm(updatedAccountDetails, backUpAccountSummary)) {
            const updateAddress = UsersProfileDetailsService.updateAccountContactSummary(
                updatedAccountDetails.accountSummaryVM.value,
                authHeader
            );
            updateCalls.push(updateAddress);
        }

        if (billingMethodChangedInForm(updatedAccountDetails, backupBillingPreference)) {
            const updateBillingMethod = AccountBillingDetailsService
                .setInvoiceDeliveryMethodForAccount(
                    updatedAccountDetails.billingPreference,
                    updatedAccountDetails.accountSummaryVM.value.accountNumber,
                    authHeader
                );
            updateCalls.push(updateBillingMethod);
        }

        Promise.all(updateCalls)
            .then(
                () => {
                    resetEditState();
                },
                () => {
                    updateDisplayError(true);
                }
            )
            .finally(() => updateDisplayLoader(false));
    }, [accountsDetails, authHeader, editModeState, resetEditState, updateAllAccounts, translator]);

    const getAccountTitle = useCallback(
        (accountSummaryVM) => {
            if (isMultiAccount && hasPoliciesInForce(accountSummaryVM)) {
                const accountName = accountSummaryVM.accountContact.value;
                const accountNumber = accountSummaryVM.accountNumber.value;
                return translator(messages.accountTitleWithInForcePolicies, {
                    accountName: accountName.displayName,
                    accountNumber: accountNumber
                });
            }

            if (isMultiAccount && !hasPoliciesInForce(accountSummaryVM)) {
                return translator(messages.accountTitleWithNoInForcePolicies);
            }

            return translator(messages.accountDetails);
        },
        [isMultiAccount, translator]
    );

    const onIsBillingAddressSameChange = useCallback(
        (componentName) => {
            return (value, path) => {
                if (value) {
                    disregardFieldValidation(componentName);
                }
                _.set(accountsDetails, path, value);
                updateAccounts(accountsDetails);
            };
        },
        [accountsDetails, disregardFieldValidation, updateAccounts]
    );

    const isReadOnly = useCallback((index) => editModeState.accountInEditIndex !== index, [
        editModeState.accountInEditIndex
    ]);

    const generatedProps = useMemo(() => {
        const { accounts = [] } = accountsDetails;
        const { capabilitiesConfig } = appConfig;
        const overrides = accounts.map(({ accountSummaryVM }, index) => {
            return {
                [`accountItemContainer${index}`]: {
                    className:
                        isReadOnly(index)
                        && editModeState.accountInEditIndex !== NOT_EDIT_MODE_INDEX
                            ? styles.accountSectionReadonly
                            : styles.accountSummaryContainer
                },
                [`titleAccount${index}`]: {
                    content: getAccountTitle(accountSummaryVM)
                },
                [`editButton${index}`]: {
                    visible: editModeState.accountInEditIndex === NOT_EDIT_MODE_INDEX
                },
                [`errorMsg${index}`]: {
                    visible: displayError && !isReadOnly(index)
                },
                [`accountContactContainer${index}`]: {
                    readOnly: isReadOnly(index)
                },
                [`isBillingAddressSame${index}`]: {
                    visible: !isReadOnly(index),
                    onValueChange: onIsBillingAddressSameChange(`billingContactContainer${index}`)
                },
                [`billingAddressIsTheSame${index}`]: {
                    visible: isReadOnly(index) && accountSummaryVM.isBillingAddressSame.value
                },
                [`billingContactContainer${index}`]: {
                    visible: !accountSummaryVM.isBillingAddressSame.value,
                    readOnly: isReadOnly(index)
                },
                [`billingDeliveryTitle${index}`]: {
                    visible: isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })
                },
                [`deliveryMethod${index}`]: {
                    readOnly: isReadOnly(index),
                    visible: isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })
                },
                [`applyToAllAccountsContainer${index}`]: {
                    visible: !isReadOnly(index) && isMultiAccount
                },
                [`actionsContainer${index}`]: {
                    visible: !isReadOnly(index)
                },
                [`saveButton${index}`]: {
                    disabled: !isComponentValid
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [
        accountsDetails,
        getAccountTitle,
        editModeState.accountInEditIndex,
        isReadOnly,
        isMultiAccount,
        isComponentValid,
        onIsBillingAddressSameChange,
        displayError
    ]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phonewide: {
                labelPosition: 'top',
            },
            phone: {
                labelPosition: 'top',
            }
        },
        globalizationChooserField: {
            languageValue: localeService.getStoredLanguage()
        },
        ...generatedProps
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            enterEditMode,
            handleCancel,
            updateContactDetails,
            onValidate,
            updateLanguage: localeService.saveLanguage
        },
        resolveComponentMap: {
            userdetailscomponent: UserDetailsComponent
        }
    };

    if (displayLoader) {
        return <Loader />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={accountsDetails}
            overrideProps={overrideProps}
            onModelChange={updateAccounts}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default withViewModelService(UsersProfile);
