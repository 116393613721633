import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import _ from 'lodash';
import {
    XCenterUserAuthenticationServiceFactory,
    OAuthServiceFactory,
    ERRORS
} from 'gw-portals-auth-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import LogoutWarningModal from './LogoutWarningModal';

export { ERRORS } from 'gw-portals-auth-js';

function initializeOAuthConfig() {
    const authConfig = appConfig.authentication;

    const serverConfig = authConfig.servers[authConfig.authServer];

    return {
        ...authConfig,
        ...serverConfig
    };
}

function getAuthHeader(anAuthData) {
    const { accessToken } = anAuthData || {};
    const authHeader = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined;
    return authHeader;
}

function normalizeUserName(userData) {
    const { user_name: userName, name } = userData;
    return userName || name;
}

export const AuthContext = React.createContext({});

function isAuthenticated(authData) {
    return _.get(authData, 'isLoggedIn', false);
}

function normalizeAuthData(authData) {
    if (isAuthenticated(authData)) {
        const { userData, ...otherData } = authData;
        const newUserData = _.cloneDeep(userData);
        newUserData.user_name = normalizeUserName(userData);
        return {
            ...otherData,
            ...newUserData
        };
    }
    return authData;
}

export function DigitalAuthContextProvider({ children, onAuthDataCreation }) {
    const [authData, updateAuthData] = useState({ isLoggedIn: false });
    const [isAuthInProgress, updateIsAuthInProgress] = useState(false);

    const normalizeAndUpdate = useCallback(
        (newAuthData) => {
            if (_.isUndefined(newAuthData.authUserData) && newAuthData.isLoggedIn) {
                Promise.resolve(onAuthDataCreation([], getAuthHeader(newAuthData.userData))).then(
                    (userAuthDataResponse) => {
                        const mergedAuthData = {
                            ...newAuthData,
                            authUserData: userAuthDataResponse
                        };
                        updateAuthData(normalizeAuthData(mergedAuthData));
                    }
                );
            }
            updateAuthData(normalizeAuthData(newAuthData));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const config = useMemo(initializeOAuthConfig, []);
    const authService = useMemo(() => {
        const service = OAuthServiceFactory(config);
       /*  const service = appConfig.authentication.serverSetUpLocal
            ? XCenterUserAuthenticationServiceFactory(config)
            : OAuthServiceFactory(config); */

        service.onLoginStateChange(normalizeAndUpdate);
        return service;
    }, [config, normalizeAndUpdate]);

    // authService might include some getters, therefore we're using omit
    // instead of a more concise object spread
    const { refreshAccessToken } = authService;
    const restOfOAuthFunctions = _.omit(authService, 'refreshAccessToken');

    const refreshToken = useCallback(() => {
        return refreshAccessToken().then((response) => {
            const {
                res: { accessToken }
            } = response;
            const newAuthData = {
                ...authData,
                accessToken
            };
            updateAuthData(newAuthData);
        });
    }, [authData, refreshAccessToken]);

    useEffect(() => {
        // Skip authentication with cookies when landing page is reset password
        if (!/(\/auth\/register)/.test(window.location.pathname)) {
            updateIsAuthInProgress(true);
            authService.loginWithCurrentCookies()
                .catch((err) => {
                    if (_.get(err, 'error') === ERRORS.notLoggedIn) {
                        updateAuthData({ isLoggedIn: false });
                    } else {
                        throw err;
                    }
                })
                .finally(() => updateIsAuthInProgress(false));
        }
    }, [authService]);

    const authHeader = getAuthHeader(authData);

    const {
        inactivityIntervalMins,
        logoutConfirmationIntervalMins
    } = appConfig.sessionConfig;
    const authContext = {
        ...authData,
        isAuthInProgress,
        authHeader,
        ...authService,
        refreshToken,
        ...restOfOAuthFunctions
    };
    return (
        <AuthContext.Provider value={authContext}>
            <LogoutWarningModal
                onEndSession={authService.logout}
                modalAutoCloseTimeout={logoutConfirmationIntervalMins}
                userInactivityTimeout={inactivityIntervalMins}
            />
            {children}
        </AuthContext.Provider>
    );
}

DigitalAuthContextProvider.propTypes = {
    children: PropTypes.node,
    onAuthDataCreation: PropTypes.func
};

DigitalAuthContextProvider.defaultProps = {
    children: undefined,
    onAuthDataCreation: _.noop
};

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const useAuthentication = () => useContext(AuthContext);

export function withAuthenticationContext(WrappedComponent) {
    function WithAuthenticationContext(props) {
        const authProps = useAuthentication();
        return <WrappedComponent {...authProps} {...props} />;
    }
    hoistNonReactStatic(WithAuthenticationContext, WrappedComponent);
    WithAuthenticationContext.displayName = `WithAuthenticationContext(${getDisplayName(
        WrappedComponent
    )})`;
    return WithAuthenticationContext;
}

export default AuthContext.Consumer;
