import React, { useContext, useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import VehicleCoverageTable from '../VehicleCoverageTable/VehicleCoverageTable';
import metadata from './PolicyVehiclesDriversInfo.metadata.json5';
import messages from './PolicyVehiclesDriversInfo.messages';
import styles from './PolicyVehiclesDriversInfo.module.scss';

function PolicyVehiclesDriversInfo(props) {
    const translator = useTranslator();
    const [openItems, setOpenItems] = useState([]);
    const { value: paCoverages } = props;

    const intl = useContext(IntlContext);

    const getFormattedDate = useCallback((item) => {
        return intl.formatDate(new Date(item), { year: 'numeric', month: 'short', day: 'numeric' });
    }, [intl]);

    const getLimitDeductibleValue = (terms, type) => {
        if (!_.isEmpty(terms) && terms[0].modelType === type) {
            return terms[0].amount;
        }
        return '-';
    };

    const getCoverage = useCallback(
        (coverages, isLineCoverage) => {
            return coverages.map((coverage) => {
                return {
                    name: coverage.name,
                    coverageType: isLineCoverage ? translator(messages.vehicleInfoPolicy) : null,
                    limit: getLimitDeductibleValue(coverage.terms, 'Limit'),
                    deductible: getLimitDeductibleValue(coverage.terms, 'Deductible'),
                    premium: coverage.premium
                };
            });
        },
        [translator]
    );

    const lineCoverage = getCoverage(paCoverages.coverageDTOs, true);

    const toggleShowHide = useCallback(
        (item) => {
            const openedItem = _.clone(openItems);
            const index = _.indexOf(openedItem, item);
            if (index === -1) {
                openedItem.push(item);
                setOpenItems(openedItem);
            } else {
                setOpenItems(_.slice(openedItem, index, 0));
            }
        },
        [openItems]
    );

    const generateVehicleOverrides = useCallback(() => {
        const overrides = paCoverages.vehicleDTOs.map((vehicleDTO, index) => {
            const vehicleCoverage = getCoverage(vehicleDTO.coverages);
            return {
                [`vehicleCoverageInfo${index}`]: {
                    data: _.concat(lineCoverage, vehicleCoverage),
                    show: openItems
                },
                [`showContentToggleLink${index}`]: {
                    onClick: () => toggleShowHide(index),
                    icon: _.includes(openItems, index) ? 'mi-arrow-drop-up' : 'mi-arrow-drop-down',
                    content: _.includes(openItems, index)
                        ? translator(messages.hideVehicleCoverages)
                        : translator(messages.showVehicleCoverages)
                },
                [`vehicleCoverageContainer${index}`]: {
                    visible: _.includes(openItems, index)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [getCoverage, lineCoverage, openItems, paCoverages, toggleShowHide, translator]);

    const generateDriverOverrides = useCallback(() => {
        const overrides = paCoverages.driverDTOs.map((driverDTO, index) => {
            return {
                [`driverDOB${index}`]: {
                    value: getFormattedDate(new Date(
                        driverDTO.dateOfBirth.year,
                        driverDTO.dateOfBirth.month,
                        driverDTO.dateOfBirth.day
                    ))
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [getFormattedDate, paCoverages]);

    const overrides = {
        ...generateDriverOverrides(),
        ...generateVehicleOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            vehiclecoverage: VehicleCoverageTable
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={paCoverages}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyVehiclesDriversInfo.propTypes = {
    value: PropTypes.shape({
        coverageDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        vehicleDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        driverDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        additionalLiabilityCoverages: PropTypes.arrayOf(PropTypes.shape({})),
        additionalPropertyCoverages: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
};

export default PolicyVehiclesDriversInfo;
