import { defineMessages } from 'react-intl';

export default defineMessages({
    dashboard: {
        id: 'portal.user.views.home-page-layout.Approve Portal User',
        defaultMessage: 'Approve Portal User',
    },
    serviceRequests: {
        id: 'portal.user.views.home-page-layout.Service Requests',
        defaultMessage: 'Service Requests',
    },
    registerUserPhoneNumber: {
        id: 'portal.user.views.dashboard-table.Phone Number',
        defaultMessage: 'Phone Number',
    },
    registerUserFEIN: {
        id: 'portal.user.views.dashboard-table.FEIN',
        defaultMessage: 'FEIN',
    },

    searchNameOrClaim: {
        id: 'portal.user.views.dashboard-table.service-request.Search name',
        defaultMessage: 'Search name or status...',
    },
    registerUserFirstName: {
        id: 'portal.user.views.dashboard-table.First name',
        defaultMessage: 'First Name',
    },
    registerUserLastName: {
        id: 'portal.user.views.dashboard-table.Last name',
        defaultMessage: 'Last Name',
    },
    registerUserStatus:{
        id: 'portal.user.views.dashboard-table.regStatus',
        defaultMessage: 'Status'
    },
    userapproveTitleCompleteWork: {
        id: 'userapprove.views.complete-work.Approve User',
        defaultMessage: 'Approve User'
    },
    userapproveButtonCompleteWork: {
        id: 'userapprove.views.complete-work.button.Approve',
        defaultMessage: 'Approve'
    },
    userapproveMessageCompleteWork: {
        id: 'userapprove.views.complete-work.button.Are you sure you want to approve this user?',
        defaultMessage: 'Are you sure you want to approve this user?'
    },
    userapproveButtonCancelWork: {
        id: 'userapprove.views.cancel-work.button.Cancel Work',
        defaultMessage: 'Cancel'
    },
    userrejectTitleCompleteWork: {
        id: 'userreject.views.complete-work.Approve User',
        defaultMessage: 'Reject User'
    },
    userrejectButtonCompleteWork: {
        id: 'userreject.views.complete-work.button.Approve',
        defaultMessage: 'Reject'
    },
    userrejectMessageCompleteWork: {
        id: 'userreject.views.complete-work.button.Are you sure you want to approve this user?',
        defaultMessage: 'Are you sure you want to reject this user?'
    },
    userrejectButtonCancelWork: {
        id: 'userreject.views.cancel-work.button.Cancel',
        defaultMessage: 'Cancel'
    }
});
