import React, {
    useCallback, useEffect, useMemo
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { PolicyService } from 'gw-capability-policy';
import { DocumentService } from 'gw-capability-policydocument';
import { useModal } from '@jutro/components';
import { messages as commonMessages } from 'gw-platform-translations';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import FileUploadViewerComponent from './FileUploadViewerComponent/FileUploadViewerComponent';
import metadata from './PolicyCommonDocuments.metadata.json5';
import messages from './PolicyCommonDocuments.messages';
import styles from './PolicyCommonDocuments.module.scss';

function PolicyCommonDocuments(props) {
    const {
        showAlert,
        showConfirm
    } = useModal();

    const {
        value: { documentDTOs: policyCommonDocuments, policyNumber, canUploadDocument },
        writableDocuments,
        updateWritableDocuments
    } = props;
    const { authHeader } = useAuthentication();

    const publicDocuments = useMemo(() => {
        return _.filter(policyCommonDocuments, (document) => {
            return !document.isAuthor;
        });
    }, [policyCommonDocuments]);

    useEffect(() => {
        const writableDoc = _.filter(policyCommonDocuments, (document) => {
            return document.isAuthor;
        });
        updateWritableDocuments(writableDoc);
    }, [policyCommonDocuments, updateWritableDocuments]);

    const claimsDocUploadToken = useCallback(async () => {
        try {
            const uploadTokenID = await PolicyService.getDocumentUploadToken([], authHeader);
            return uploadTokenID;
        } catch (e) {
            return showAlert({
                title: commonMessages.errorUploadTitle,
                message: commonMessages.errorGenerateUploadToken,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }
    }, [authHeader, showAlert]);

    const deleteDocument = useCallback(
        (item) => {
            if (!item.canDelete) {
                return;
            }

            const { publicID } = item;
            showConfirm({
                title: messages.removeDocument,
                message: messages.confirmRemoveDocument,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.documentRemoveConfirmYes,
                cancelButtonText: messages.documentRemoveConfirmNo
            }).then(async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                try {
                    const isDocumentDeleted = await PolicyService.removeDocument(
                        publicID,
                        authHeader
                    );
                    if (isDocumentDeleted) {
                        const newDocuments = _.filter(writableDocuments, (doc) => {
                            return doc.workingPublicID !== publicID;
                        });
                        updateWritableDocuments(newDocuments);
                    }
                } catch (documentDeletionError) {
                    showAlert({
                        title: messages.documentDeletionFailed,
                        message: messages.documentCannotDeleted,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
                return true;
            }, _.noop);
        },
        [authHeader, showAlert, showConfirm, updateWritableDocuments, writableDocuments]
    );

    const getDocumentDownloadLink = useCallback(
        (item) => {
            const { publicID, sessionID } = item;
            return DocumentService.downloadPolicyDocument(publicID, sessionID);
        },
        []
    );

    const uploadDocument = useCallback(
        async (item) => {
            const documentMetaDataTemplate = {
                docUID: '001',
                documentType: 'VFD Rosters',
                securityType: 'unrestricted',
                status: 'final',
                policyNumber: policyNumber,
                name: item.name,
                mimeType: item.type,
                SessionID: await claimsDocUploadToken()
            };
            try {
                const recentUploadedDoc = await PolicyService.uploadDocument(
                    item,
                    documentMetaDataTemplate,
                    authHeader
                );
                if (!_.isEmpty(recentUploadedDoc)) {
                    updateWritableDocuments((docs) => [...docs, recentUploadedDoc]);
                }
            } catch (error) {
                showAlert({
                    title: messages.documentUploadFailed,
                    message: messages.documentCannotUploaded,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
        },
        [authHeader, claimsDocUploadToken, policyNumber, showAlert, updateWritableDocuments]
    );

    const overrides = {
        policyDocuments: {
            visible: !_.isEmpty(publicDocuments)
        },
        readOnlyDocumentFileUploadComponent: {
            data: {
                isReadOnly: true,
                documents: publicDocuments
            },
            getDownloadLink: getDocumentDownloadLink
        },
        writableDocumentFileUploadComponent: {
            data: {
                isReadOnly: !canUploadDocument,
                documents: writableDocuments
            },
            getDownloadLink: getDocumentDownloadLink
        }
    };

    const resolvers = {
        resolveComponentMap: {
            documentscomponent: FileUploadViewerComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onDocumentDelete: deleteDocument,
            onDocumentUpload: uploadDocument
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                policyCommonDocuments,
                id,
                path,
                overrides
            );
        },
        [policyCommonDocuments, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyCommonDocuments}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

PolicyCommonDocuments.propTypes = {
    value: PropTypes.shape({
        documentDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        policyNumber: PropTypes.string,
        canUploadDocument: PropTypes.bool
    }),
    writableDocuments: PropTypes.shape([]).isRequired,
    updateWritableDocuments: PropTypes.shape([]).isRequired
};

PolicyCommonDocuments.defaultProps = {
    value: {
        documentDTOs: [],
        policyNumber: '',
        canUploadDocument: false
    }
};

export default withRouter(PolicyCommonDocuments);
