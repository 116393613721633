import _ from 'lodash';
import classNames from 'classnames';
import styles from '../RepairFacilitiesList/RepairFacilitiesList.module.scss';

const titleClassNames = classNames(styles.vendorInformation, styles.vendorName);

const infoWindowStaticContent = (location) => {
    const {
        title,
        address
    } = location;

    const addressLine1 = _.get(address, 'addressLine1', _.get(address, 'addressLine1Kanji', ''));
    const addressLine2 = _.get(address, 'addressLine2', _.get(address, 'addressLine2Kanji', ''));
    const addressLine3 = _.get(address, 'addressLine3', _.get(address, 'addressLine3Kanji', ''));
    const city = _.get(address, 'city', _.get(address, 'cityKanji', ''));
    const state = _.get(address, 'state');
    const cityState = [city, state].join(',');
    const postalCode = _.get(address, 'postalCode', '');

    const dataArray = [addressLine1, addressLine2, addressLine3, cityState, postalCode];
    const content = dataArray.map((val) => `<div>${val}</div>`).join('');

    return `<div class="${titleClassNames}">
                ${title}
            </div>
            <div class=${styles.vendorInformation}>
                ${content}
            </div>`;
};

export const infoWindowContent = (facility) => {
    const {
        contactName,
        workNumber,
        emailAddress1
    } = facility;

    return `<div>
        <div class='${titleClassNames}'>
            ${contactName}
        </div>
        <div class='${styles.vendorInformation}'>
            ${_.get(facility, 'primaryAddress.displayName')}
        </div>
        <div class='${styles.vendorInformation}'>
            <div>${workNumber}</div>
            <div>${emailAddress1}</div>
        </div>
    </div>`;
};

export default infoWindowStaticContent;
