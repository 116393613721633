import React, {
    useContext,
    useMemo,
    useState,
    useCallback,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import {
    DropdownSelectField,
    Button,
    useModal
} from '@jutro/components';
import { RepairFacilityService } from 'gw-capability-vendor';
import { useAuthentication } from 'gw-digital-auth-react';
import { ServiceManager } from '@jutro/services';
import { GeoCodeService } from 'gw-portals-google-maps-js';
import useRepairFacilityMap from './useRepairFacilityMap';
import { RepairFacilityFullScreenModal } from './RepairFacilityDetails/RepairFacilityDetails';
import RepairFacilitiesSearch from './RepairFacilitiesSearch/RepairFacilitiesSearch';
import RepairFacilitiesList from './RepairFacilitiesList/RepairFacilitiesList';
import styles from './RepairFacilitiesMap.module.scss';
import mapMessages from './RepairFacilitiesMap.messages';
import RepairFacilitiesSearchOption from './RepairFacilitiesSearchOption/RepairFacilitiesSearchOption';
import searchMessages from './RepairFacilitiesSearch/RepairFacilitiesSearch.messages';

const DEFAULT_SEARCH_RADIUS = '6';
const DEFAULT_UNIT_DISTANCE = 'Mile';
const DEFAULT_MAX_NO_RESULTS = 50;

function RepairFacilitiesMap(props) {
    const {
        showModal
    } = useModal();

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const [lossLocation, setLossLocation] = useState({});
    const [policyLocation, setPolicyLocation] = useState({});
    const [locationType, updateLocationType] = useState(undefined);
    const [selectedFacility, updateFacility] = useState(undefined);
    const {
        selectRepairFacilityCallback, lossCause, contactName, claimVM, goNext
    } = props;

    const {
        mapsApi,
        map,
        mapCenter,
        myLocation,
        setMapElement,
        moveMapToCenter,
        addMarker,
        clearMarkers
    } = useRepairFacilityMap();

    const staticLocationGeocodes = useCallback(() => {
        const lossLocationValue = _.get(claimVM, 'lossLocation');
        const primaryAddress = _.get(claimVM, 'mainContact.primaryAddress');
        const geoCodeServiceObj = new GeoCodeService();

        /*
         * Predefine Loss Location address geocode
         */
        if (_.get(lossLocationValue, 'postalCode')) {
            geoCodeServiceObj.geocodeAddress(lossLocationValue).then((cords) => {
                setLossLocation({
                    address: lossLocationValue,
                    geocode: cords,
                    title: translator(searchMessages.incident)
                });
            });
        }

        if (_.get(primaryAddress, 'postalCode')) {
            geoCodeServiceObj.geocodeAddress(primaryAddress).then((cords) => {
                setPolicyLocation({
                    address: primaryAddress,
                    geocode: cords,
                    title: translator(searchMessages.policyAddress)
                });
            });
        }
    }, [claimVM, translator]);

    const writeValue = useCallback((value) => {
        updateLocationType(value);
    }, []);

    const getServiceRelatedToLossCause = useCallback((lossCauseCode) => {
        switch (lossCauseCode) {
            case 'glassbreakage':
                return 'autoinsprepairglass';
            case 'theftparts':
                return 'autoinsprepairaudio';
            default:
                return 'autoinsprepairbody';
        }
    }, []);

    const mapToContactModel = useCallback((vendor) => ({
        addressBookUID: vendor.addressBookUID,
        contactName: vendor.vendorName,
        contactType: 'CompanyVendor',
        subtype: 'AutoRepairShop',
        emailAddress1: vendor.email,
        primaryAddress: {
            spatialPoint: vendor.geocode,
            ...vendor.primaryAddress
        },
        primaryPhoneType: 'work',
        workNumber: vendor.phone,
        proximateDistance: vendor.proximateDistance,
        score: vendor.score,
        geocode: vendor.geocode
    }), []);

    const getSortedRepairFacilities = useCallback((isAlphabeticalOrder, repairFacilities) => {
        if (!repairFacilities) {
            return [];
        }

        const localeService = ServiceManager.getService('locale-service');
        const locales = `${localeService.getPreferredLanguage().replace('_', '-')}-u-kn-true`;
        const sortProperty = isAlphabeticalOrder ? 'contactName' : 'proximateDistance';

        return repairFacilities.slice().sort((x, y) => x[sortProperty].localeCompare(
            y[sortProperty],
            locales
        ));
    }, []);

    const normalizeLongitude = useCallback((longitude) => {
        const divisor = 360;
        let result = longitude % divisor;

        if (result <= 0) {
            result += divisor;
        }

        if (result > divisor / 2) {
            result -= divisor;
        }

        return result;
    }, []);

    const getNormalizedCoordinates = useCallback(
        (coords) => {
            const { longitude } = coords;

            if (longitude <= -180 || longitude > 180) {
                // eslint-disable-next-line no-param-reassign
                coords.longitude = normalizeLongitude(longitude);
            }

            return coords;
        },
        [normalizeLongitude]
    );

    const payloadForSearch = useCallback((coords) => ({
        specialistServiceCodes: [getServiceRelatedToLossCause(lossCause)],
        proximitySearchGeocode: getNormalizedCoordinates(coords),
        searchRadius: DEFAULT_SEARCH_RADIUS,
        unitOfDistance: DEFAULT_UNIT_DISTANCE,
        maxNumberOfResults: DEFAULT_MAX_NO_RESULTS
    }), [getNormalizedCoordinates, getServiceRelatedToLossCause, lossCause]);

    const getSearchList = useCallback(async (coords) => {
        // search api
        const searchList = await RepairFacilityService.getVendorsNearby(
            payloadForSearch(coords),
            authHeader
        );

        const mapToContact = searchList.vendors.map((vendor) => mapToContactModel(vendor));
        const sortedVendorsList = getSortedRepairFacilities(true, [...mapToContact]);

        return sortedVendorsList;
    }, [
        authHeader,
        getSortedRepairFacilities,
        mapToContactModel,
        payloadForSearch
    ]);

    const dropdownOptions = useMemo(() => [{
        code: 'policy',
        name: translator(searchMessages.policyAddress)
    }, {
        code: 'incident',
        name: translator(searchMessages.incident)
    }, {
        code: 'navigator',
        name: translator(searchMessages.myLocation)
    }, {
        code: 'custom',
        name: translator(searchMessages.mySelectedLocation)
    }], [translator]);

    const searchOnMap = useCallback(() => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: true,
            repairFacility: {},
            claimVM,
            selectCallback: selectRepairFacilityCallback,
            goNext,
            getSearchList,
            policyLocation,
            lossLocation
        };

        return showModal(
            <RepairFacilityFullScreenModal {...componentProps} />
        );
    }, [
        claimVM,
        selectRepairFacilityCallback,
        goNext,
        getSearchList,
        policyLocation,
        lossLocation,
        showModal
    ]);

    useEffect(() => {
        staticLocationGeocodes();
    }, [staticLocationGeocodes]);

    useEffect(() => {
        setMapElement('mapArea');
    }, [setMapElement]);

    useEffect(() => {
        const selectedLocation = _.get(claimVM, 'lobs.personalAuto.repairOption.repairFacility');

        updateFacility(selectedLocation);
    }, [claimVM]);

    return (
        <div className={styles.googleMapContainer}>
            <div className={styles.googleMap}>
                {
                    (breakpoint === 'phoneWide' || breakpoint === 'phone') && (
                        <React.Fragment>
                            <div className={styles.closestRepair}>
                                <p>{translator(mapMessages.closestRepairFacilities)}</p>
                                <DropdownSelectField
                                    id="locationType"
                                    alwaysShowPlaceholder={false}
                                    availableValues={dropdownOptions}
                                    onValueChange={writeValue}
                                    value={locationType}
                                />
                            </div>
                            <Button
                                className={styles.searchButton}
                                onClick={searchOnMap}
                            >
                                {translator(mapMessages.searchOnMap)}
                            </Button>
                        </React.Fragment>
                    )
                }
                <div id="mapArea" className={styles.mapArea} />
                {
                    breakpoint !== 'phone' && (
                        <React.Fragment>
                            <RepairFacilitiesSearch
                                currentMap={map}
                                myLocation={myLocation}
                                mapCenter={mapCenter}
                            />
                            <RepairFacilitiesSearchOption
                                policyLocation={policyLocation}
                                lossLocation={lossLocation}
                                currentMap={map}
                                mapsApi={mapsApi}
                            />
                        </React.Fragment>
                    )
                }
            </div>
            <RepairFacilitiesList
                selectCallback={selectRepairFacilityCallback}
                contactName={contactName}
                mapCenter={mapCenter}
                goNext={goNext}
                policyLocation={policyLocation}
                lossLocation={lossLocation}
                moveMapToCenter={moveMapToCenter}
                addMarker={addMarker}
                clearMarkers={clearMarkers}
                getSearchList={getSearchList}
                selectedFacility={selectedFacility}
                updateFacility={updateFacility}
            />
        </div>
    );
}

RepairFacilitiesMap.propTypes = {
    selectRepairFacilityCallback: PropTypes.func.isRequired,
    lossCause: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    claimVM: PropTypes.shape({}).isRequired,
    goNext: PropTypes.func.isRequired
};

export default RepairFacilitiesMap;
