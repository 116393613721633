import React, {
    useState, useEffect, useCallback, useContext
} from 'react';
import _ from 'lodash';
import { Link } from 'gw-components-platform-react';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import {
    Loader, withModalContext, useModal, Button
} from '@jutro/components';

import metadata from './UserApprovePage.metadata.json5';
import messages from './UserApprovePage.messages';
import styles from './UserApprovePage.module.scss';

const actionsApproveCell = () => {
    return '';
};

const actionsRejectCell = () => {
    return '';
};

const searchUserTableFilter = (filterValue) => (row) => {
    const val = filterValue.toLowerCase();
    return (
        row.firstName.toLowerCase().includes(val)
        || row.lastName.toLowerCase().includes(val)
        || row.regStatus.toLowerCase().includes(val)
    );
};

const searchUserViewTableFilter = (filterValue) => (row) => {
    const val = filterValue.toLowerCase();
    return (
        row.firstName.toLowerCase().includes(val)
        || row.lastName.toLowerCase().includes(val)
        || row.regStatus.toLowerCase().includes(val)
    );
};

function UserApprovePage() {
    const {
        showConfirm
    } = useModal();
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const [isLoading, setLoadingState] = useState(false);
    const [registerUserData, setRegisterUserData] = useState([]);
    const [registerUserViewData, setRegisterUserViewData] = useState([]);
    const { PolicyService } = useDependencies('PolicyService');

    const filterRegisterUserItems = useCallback((userItems) => {
        // second condition checks if request has a approved quote attached to it
        const usersData = _.orderBy(userItems.filter((item) => item.regStatus === 'Pending'), ['regStatus'], ['firstName'], ['lastName']);
        const usersViewData = _.orderBy(userItems.filter((item) => item.regStatus !== 'Pending'), ['regStatus'], ['firstName'], ['lastName']);
        setRegisterUserData(usersData);
        setRegisterUserViewData(usersViewData);
    }, []);

    const revokeDataRow = (newRow) => {
        const newData = [...registerUserData];
        PolicyService.rejectRegisterUser(newRow, authHeader).then(
            filterRegisterUserItems
        ).finally(() => {
        });
    };

    const rejectDataRow = useCallback(
        (newRow) => {
            const newData = [...registerUserData];
            showConfirm({
                title: translator(messages.userrejectTitleCompleteWork),
                message: translator(messages.userrejectMessageCompleteWork),
                confirmButtonText: translator(messages.userrejectButtonCompleteWork),
                cancelButtonText: translator(messages.userrejectButtonCancelWork)
            }).then(
                (res) => {
                    if (res === 'confirm') {
                        PolicyService.rejectRegisterUser(newRow, authHeader).then(
                            filterRegisterUserItems
                        ).finally(() => {
                        });
                    }
                }
            ).catch(_.noop);
        }, [PolicyService, authHeader, filterRegisterUserItems, registerUserData, translator]
    );

    const approveDataRow = useCallback((newRow, rowId) => {
        const newData = [...registerUserData];
        showConfirm({
            title: translator(messages.userapproveTitleCompleteWork),
            message: translator(messages.userapproveMessageCompleteWork),
            confirmButtonText: translator(messages.userapproveButtonCompleteWork),
            cancelButtonText: translator(messages.userapproveButtonCancelWork)
        }).then((res) => {
            if (res === 'confirm') {
                PolicyService.approveRegisterUser(newRow, authHeader).then(
                    filterRegisterUserItems
                ).finally(() => {
                });
            }
        }).catch(_.noop);
    }, [PolicyService, authHeader, filterRegisterUserItems, registerUserData, translator]);

    const getTableOverrides = useCallback(() => {
        return {
            actionsApproveViewColumn: { onCell: actionsApproveCell },
            actionsRejectViewColumn: { onCell: actionsRejectCell }
        };
    }, []);

    useEffect(() => {
        setLoadingState(true);
        PolicyService.getRegisterUser(authHeader).then(
            filterRegisterUserItems
        ).finally(() => {
            setLoadingState(false);
        });
    }, [PolicyService, authHeader, filterRegisterUserItems]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    const override = {
        '@field': {
            labelPosition: 'left'
        },
        userRequestsTable: {
            data: registerUserData,
            filter: searchUserTableFilter
        },
        userRequestsViewTable: {
            data: registerUserViewData,
            filter: searchUserViewTableFilter
        },
        ...getTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            actionApproveFunc: approveDataRow,
            actionRejectFunc: rejectDataRow
        }
    };

    // return renderContentFromMetadata(metadata.pageContent, override, resolvers);
    return (<MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={override}
        {...resolvers}
    />);
}

export default withRouter(withModalContext(UserApprovePage));
