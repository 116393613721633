import { defineMessages } from 'react-intl';

export default defineMessages({
    account: {
        id: 'header.links.Account',
        defaultMessage: 'Account'
    },
    faq: {
        id: 'header.links.FAQ',
        defaultMessage: 'FAQ'
    },
    logout: {
        id: 'header.links.Logout',
        defaultMessage: 'Logout'
    },
    userapprove: {
        id: 'header.links.userApprove',
        defaultMessage: 'User Administration'
    }
});
