/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
import React, {
    useContext, useCallback, useEffect, useState, useMemo
} from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { MetadataContent } from '@jutro/uiconfig';
import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withModalContext } from '@jutro/components';
import { PdfCustomModal } from 'gw-capability-enrollment-react';
import { PDFViewer } from 'gw-document-pdfdisplay-react';
import { checkEmailValidity, isEmpty } from '../LoginUtil';
import UserRegisterMessageComponent from '../UserRegisterMessageComponent/UserRegisterMessageComponent';
import metadata from './UserSignUpComponent.metadata.json5';
import messages from './UserSignUpComponent.messages';
import styles from './UserSignUpComponent.module.scss';

const { siteKeyMap, useRecaptcha } = appConfig;

const PAGEVIEW = {
    registerFormView: 'REGISTER_FORM_VIEW',
    successView: 'REGISTER_SUCCESS',
    failFeinView: 'REGISTER_FEIN_FAIL',
    failUserIdView: 'REGISTER_UserID_FAIL'
};

const ERRORVIEW = {
    feinnotExist: 'REGISTER_FORM_VIEW',
    successView: 'REGISTER_SUCCESS',
    failFeinView: 'REGISTER_FEIN_FAIL',
    failUserIdView: 'REGISTER_UserID_FAIL'
};

function UserSignUpComponent(props) {
    const translator = useContext(TranslatorContext);
    const { EnrollmentService } = useDependencies('EnrollmentService');
    const [pageError, updatePageError] = useState();
    const [formData, updateFormData] = useState({});
    const { authHeader } = useAuthentication();
    const { isComponentValid, registerComponentValidation } = useValidation('UserSignUpComponent');
    const [captchaChecked, updateCaptchaChecked] = useState(false);
    const [pageView, updatePageView] = useState(PAGEVIEW.registerFormView);
    const [descriptionMessage, updateDescriptionMessage] = useState('');
    const [isLoading, setLoadingState] = useState(false);
    const [result, updateResult] = useState(null);
    const [agreeTerm, updateAgreeTerm] = useState(false);

    const handleSuccessClick = useCallback((data) => {
        if (data.status === 'Success') {
            updatePageView(PAGEVIEW.successView);
        } else {
            const { description } = data;
            updateDescriptionMessage(description);
            updatePageView(PAGEVIEW.failFeinView);
        }
    }, []);

    const handleRegisterClick = useCallback(() => {
        updatePageView(PAGEVIEW.registerFormView);
    }, []);

    const handleAcceptWork = useCallback((actionData) => {
        updateResult(actionData);
    }, []);

    const triggerModal = useCallback(() => {
        const { modalContext } = props;
        modalContext.showModal(
            <PdfCustomModal message="" confirmButtonText="Close" cancelButtonText="Disagree">
                <PDFViewer />
            </PdfCustomModal>
        ).then(
            handleAcceptWork
        ).catch(_.noop);
    }, [handleAcceptWork]);

    const onValueChangeFuc = (value) => {
        updateAgreeTerm(value);
    };

    const disableSubmit = useCallback(() => {
        const email = _.get(formData, 'emailAddress');
        const firstName = _.get(formData, 'firstName');
        const lastName = _.get(formData, 'lastName');
        const telephone = _.get(formData, 'telephone');

        const fein = _.get(formData, 'fein');
        const ssn = _.get(formData, 'ssn');

        return !isEmpty(email) && !isEmpty(firstName) && !isEmpty(lastName)
         && !isEmpty(telephone) && (!isEmpty(fein) || !isEmpty(ssn))
         && agreeTerm && captchaChecked;
    }, [formData, agreeTerm, captchaChecked]);

    const handleUserReg = useCallback((evt) => {
        if (evt) {
            evt.preventDefault();
        }

        const email = _.get(formData, 'emailAddress');
        const firstname = _.get(formData, 'firstName');
        const lastname = _.get(formData, 'lastName');
        const policyNumber = _.get(formData, 'policynumber');
        const fein = _.get(formData, 'fein');
        const ssn = _.get(formData, 'ssn');
        const userType = _.get(formData, 'userType');
        const telephone = _.get(formData, 'telephone');
        const userid = _.get(formData, 'username');
        // const ssnReg = /^\d{3}-\d{2}-\d{4}$/;
        // const feinReg = /^[1-9]\d?-\d{7}$/;
        const reg = /^[0-9]{9}/;

        if (!checkEmailValidity(email)) {
            updatePageError('userInvalidEmail');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(firstname)) {
            updatePageError('userFirstNameIsEmpty');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(lastname)) {
            updatePageError('userLastNameIsEmpty');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(fein) && isEmpty(ssn)) {
            updatePageError('feinIsEmptyError');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (!isEmpty(fein) && !isEmpty(ssn)) {
            updatePageError('feinIsEmptyError');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (!isEmpty(ssn) && !reg.test(ssn)) {
            updatePageError('ssnFormatError');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (!isEmpty(fein) && !reg.test(fein)) {
            updatePageError('feinFormatError');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(userType)) {
            updatePageError('userTypeError');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(telephone)) {
            updatePageError('telephoneError');
            setLoadingState(false);
            return Promise.resolve();
        }
        if (isEmpty(policyNumber)) {
            updatePageError('policyNumberError');
            setLoadingState(false);
            return Promise.resolve();
        }

        if (useRecaptcha && isEmpty(captchaChecked) && !captchaChecked) {
            updatePageError('captchaCheckedError');
            return Promise.resolve();
        }
        setLoadingState(true);
        EnrollmentService.createNewUser(
            {
                userId: userid || '',
                firstName: firstname,
                lastName: lastname,
                email: email,
                fein: fein || ssn,
                telephone: telephone,
                userType: userType,
                policyNumber: policyNumber
            },
        ).then((data) => {
            updateCaptchaChecked(false);
            handleSuccessClick(data);
            updateFormData({});
            updateAgreeTerm(false);
            Promise.resolve();
        }).catch(() => {
            setLoadingState(false);
        }).finally(() => {
            setLoadingState(false);
        });
    }, [formData, captchaChecked, EnrollmentService, handleSuccessClick]);

    const verifyCaptchaCallbackfunction = useCallback((response) => {
        if (response) {
            updateCaptchaChecked(true);
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const captchaCallbackfunction = useCallback(
        () => {
        }
    );

    const backToRegistration = useCallback(
        () => {
            updatePageError(undefined);
            updatePageView(PAGEVIEW.registerFormView);
        }
    );
    const captchaExpiredCallbackfunction = useCallback((response) => {
        if (!response) {
            updateCaptchaChecked(false);
        }
    }, []);

    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const overrideProps = useMemo(
        () => ({
            '@field': {
                onValueChange: writeValue
            },
            errorMsg: {
                visible: !_.isUndefined(pageError),
                message: translator(messages[pageError])
            },
            signUpButton: {
                onTrigger: handleUserReg,
                visible: !isLoading,
                disabled: !disableSubmit()
            },
            successfulContainer: {
                visible: pageView === PAGEVIEW.successView
            },
            successfulContainerReview: {
                visible: pageView === PAGEVIEW.successView
            },
            successfulContainerQuestion: {
                visible: pageView === PAGEVIEW.successView
            },
            userSignUpContainer: {
                visible: pageView === PAGEVIEW.registerFormView
            },
            failedContainer: {
                content: translator(descriptionMessage),
                visible: pageView === PAGEVIEW.failFeinView
            },
            failedUserIdContainer: {
                visible: pageView === PAGEVIEW.failUserIdView
            },
            inlineLoaderCom: {
                visible: isLoading,
                loading: true
            },
            CheckboxField: {
                value: agreeTerm

            },
            termCheck: {
                value: agreeTerm
            },
            failedContainerMessage: {
                content: translator(descriptionMessage),
                visible: pageView === PAGEVIEW.failFeinView
            },
            captchaele: {
                sitekey: (_.find(siteKeyMap, (o) => {
                    const found = _.startsWith(window.location, o.key);

                    if (found) {
                        return o;
                    }
                }).value
                ),
                onloadCallback: captchaCallbackfunction,
                verifyCallback: verifyCaptchaCallbackfunction,
                expiredCallback: captchaExpiredCallbackfunction
            }
        }),
        [writeValue, pageError, translator, handleUserReg, isLoading, agreeTerm,
            pageView, descriptionMessage, captchaCallbackfunction, disableSubmit,
            verifyCaptchaCallbackfunction, captchaExpiredCallbackfunction]
    );

    const resolvers = useMemo(
        () => ({
            resolveValue: readValue,
            resolveCallbackMap: {
                // handleBackToSignIn: handleRegisterClick,
                onCallback: handleRegisterClick,
                onValueChangeFuc: onValueChangeFuc,
                onTriggerFunction: triggerModal,
                handleCaptchaFun: captchaCallbackfunction,
                backToRegistration: backToRegistration,
            },
            resolveClassNameMap: styles
        }),
        [captchaCallbackfunction, handleRegisterClick, readValue, triggerModal]
    );


    return <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrideProps}
        {...resolvers}
    />;
}

UserSignUpComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
   // successPath: PropTypes.string.isRequired,
    onBackToSignIn: PropTypes.func,
    onBackToLanding: PropTypes.func,
    captchaCallbackfunction: PropTypes.func
};

UserSignUpComponent.defaultProps = {
    onBackToSignIn: undefined,
    onBackToLanding: undefined,
    captchaCallbackfunction: undefined
};

export default withRouter(withModalContext(UserSignUpComponent));
