import _ from 'lodash';
import queryString from 'query-string';

import { getProxiedUrl } from 'gw-portals-url-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import pkceChallenge from './PkceHelper';
import { AuthenticationUtil } from './AuthenticationUtil';
import iframeUtil from './IframeUtil/IframeUtil';
import { waitForPostMessage } from './PostMessageUtil';
import jwtHelper from './JwtHelper';
import ERRORS from '../AuthErrors';
import { filterAuthorities } from './GrantedAuthoritiesUtil';

// 100 seconds prior to token expiration we want to request a new token
const refreshTimeOffset = 100e3;
const LOGIN_POST_MESSAGE_CHANNEL_NAME = 'login-redirect-data';// used on both redirect-login page as well
const LOGGED_USER_REDIRECT_URL = 'redirect-login.html';// expected URL to be redirected when user is logged in
const VERIFIER_LENGTH = 43;// specify the verifier length, defaults to 43

const { verifier, challenge } = pkceChallenge(VERIFIER_LENGTH);

function createAuthorizeRequestParams(oAuthConfig, nonce, oAuthStateCode) {
    let params = {};
    if (oAuthConfig?.serverSetUpLocal) {
        params = {
            response_type: 'code',
            code_challenge: challenge,
            code_challenge_method: 'S256',
            client_id: oAuthConfig.clientId,
            nonce,
            state: oAuthStateCode
        };
    } else {
        params = {
            response_type: 'token',
            client_id: oAuthConfig.clientId,
            nonce,
            state: oAuthStateCode
        };
    }

    if (oAuthConfig.requiresRedirectUrl) {
        const currentLocation = window.location.href;
        const redirectUrl = new URL('./common/redirect-login.html', currentLocation).href;
        params.redirect_uri = redirectUrl;
    }
    if (oAuthConfig.audience) {
        params.audience = oAuthConfig.audience;
    }
    if (oAuthConfig.scope) {
        params.scope = oAuthConfig.scope;
    }
    return params;
}

async function getTokensFromHash(tokens, parsedHash, oAuthConfig) {
    const scopeAuthorities = parsedHash.scope ? filterAuthorities(parsedHash.scope.split(' ')) : '';

    if (!parsedHash.access_token && !scopeAuthorities.length) {
        throw new Error('Expecting to set an access token or authorities or both');
    }
    const {
        id_token: idToken,
        access_token: accessToken,
        refresh_token: refreshToken,
        state,
    } = parsedHash;

    if (!idToken) {
        return { ...tokens, accessToken };
    }
    // if the auth solution uses an id_token: validate it
    const isValid = await jwtHelper.isValidIdToken(idToken, state, oAuthConfig);
    if (isValid !== true) {
        throw new Error('invalid token provided');
    }
    return {
        ...tokens,
        idToken,
        accessToken,
        refreshToken
    };
}

/**
 * Generates a sequence of chars for the nonce
 * @param {Number} nonceLength the length of the string to generate
 * @yields {String}
 */
function* getNonceChars(nonceLength) {
    const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < nonceLength; i += 1) {
        const nextCharPosition = Math.floor(Math.random() * possibleChars.length);
        yield possibleChars.charAt(nextCharPosition);
    }
}

function generateNonce() {
    const nonceLength = 16;
    const nonceString = Array.from(getNonceChars(nonceLength)).join('');
    return nonceString;
}

function processTokenRequestError(err, redirectUrl) {
    if (err) {
        if (err.authorizeWithoutIFrame) {
            return {
                error: ERRORS.notLoggedIn,
                redirect: redirectUrl
            };
        }
        if (err.fullPageRedirectRequired) {
            return {
                error: ERRORS.notLoggedIn,
                redirect: err.fullPageRedirectRequired
            };
        }
        if (err.error && err.error === ERRORS.expectedSrcPartOnLoad) {
            return {
                error: ERRORS.notLoggedIn
            };
        }
    } else {
        return {
            error: ERRORS.notLoggedIn
        };
    }
    // default
    return {
        error: ERRORS.loginError
    };
}

export class OAuthUtil extends AuthenticationUtil {
    constructor(oAuthConfig) {
        // singleton
        if (OAuthUtil.instance) {
            if (!_.isEqual(oAuthConfig, OAuthUtil.instance.authConfig)) {
                throw new Error('Attempt to create OAuthUtil with a different configuration');
            }
            // eslint-disable-next-line no-constructor-return
            return OAuthUtil.instance;
        }
        super(oAuthConfig);
        OAuthUtil.instance = this;

        this.tokens = {};
        this.tokenRefreshTimer = null;
        this.oAuthStateCode = null;
        this.nonce = null;
    }

    scheduleTokenRefreshReq = (onRefreshError, token, refreshToken) => {
        const currentToken = jwtHelper.decodeToken(token);
        const currentTokenExpiryWindowSecs = currentToken.exp - currentToken.iat;
        const currentTokenExpiryWindowMilliseconds = currentTokenExpiryWindowSecs * 1000;

        if (this.tokenRefreshTimer) {
            window.clearTimeout(this.tokenRefreshTimer);
        }

        const timeout = currentTokenExpiryWindowMilliseconds - refreshTimeOffset;

        this.tokenRefreshTimer = window.setTimeout(() => {
            if (refreshToken) {
                this.requestTokenRefresh({ onRefreshError }, refreshToken, token)
                    .then((res) => {
                        const newAuthData = {
                            isLoggedIn: true,
                            userData: res.userInfo,
                        };
                        this.emitLoginStateChangeEvent(
                            newAuthData
                        );
                    })
                    .catch(onRefreshError);
            } else {
                this.requestAccessToken({ onRefreshError })
                    .then((res) => {
                        const newAuthData = {
                            isLoggedIn: true,
                            userData: res.userInfo,
                        };
                        this.emitLoginStateChangeEvent(
                            newAuthData
                        );
                    })
                    .catch(onRefreshError);
            }
        }, timeout);
    };

    tokenPost = async (accessToken, refreshToken, isRefresh) => {
        const url = this.getTokenUrl(refreshToken, isRefresh);
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
        };
        if (accessToken != null) {
            headers = {
                ...headers,
                Authorization: `Bearer ${accessToken}`
            };
        }
        const tokens = await fetch(url, {
            method: 'POST',
            headers,
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error(`${url} - ${resp.statusText}`);
                }
                return resp;
            }).then((resp) => resp.json());
        return tokens;
    };

    parseTokensFromUrlHash = (parsedHash) => {
        // uaa validates using nonce
        if (this.authConfig.validate === 'nonce' && parsedHash.nonce !== this.nonce) {
            throw new Error('nonce value of token does not match the value used in request');
        }
        // auth0 and cognito validate using state
        if (this.authConfig.validate === 'state' && parsedHash.state !== this.oAuthStateCode) {
            throw new Error('state value of token does not match the value used in request');
        }
        return getTokensFromHash(this.tokens, parsedHash, this.authConfig);
    };

    getTokensFromIframe = async () => {
        const loginRedirectHash = await waitForPostMessage(LOGIN_POST_MESSAGE_CHANNEL_NAME);
        const { code } = queryString.parse(loginRedirectHash);
        if (code) {
            const tokenfromCodeData = await this.tokenPost(null, code, false);
            return getTokensFromHash(this.tokens, tokenfromCodeData, this.authConfig);
        }
        const tokens = this.parseTokensFromUrlHash(queryString.parse(loginRedirectHash));
        return tokens;
    };

    getUserInfo = async () => {
        const tokens = await this.waitTokensSet();
        const tokenDetails = await this.getIdTokenDetails(tokens);
        return tokenDetails;
    };

    loginWithCurrentCookies = async ({ onRefreshError }) => {
        try {
            const data = await this.prepareLogin({ onRefreshError });
            this.emitLoginEvent(data.userInfo);
        } catch (err) {
            if (err && err.redirect) {
                // go to external login page
                window.location.href = err.redirect;
                return;
            }
            throw err;
        }
    };

    removeTokens = () => {
        let tokenOrigin;
        if (this.tokens.accessToken) {
            tokenOrigin = jwtHelper.decodeToken(this.tokens.accessToken).origin;
        }
        const {
            accessToken,
            authorities,
            // eslint-disable-next-line camelcase
            id_token,
            ...preservedTokens
        } = this.tokens;

        this.tokens = preservedTokens;

        if (this.tokenRefreshTimer) {
            window.clearTimeout(this.tokenRefreshTimer);
        }
        this.oAuthStateCode = null;

        return tokenOrigin;
    };

    getTokenUrl = (code, isRefresh) => {
        const {
            url,
            endpoints,
            clientId,
        } = this.authConfig;

        const { env } = appConfig;
        const redirectUri = _.get(env, 'DEPLOYMENT_URL') + _.get(env, 'UAA_REDIRECT_URL');
        let serializedParams;
        if (isRefresh) {
            const params = {
                grant_type: 'refresh_token',
                refresh_token: code,
                token_format: 'jwt',
                client_id: clientId
            };
            serializedParams = queryString.stringify(params);
        } else {
            const params = {
                client_id: clientId,
                code_verifier: verifier,
                grant_type: 'authorization_code',
                code: code,
                token_format: 'jwt',
                redirect_uri: redirectUri
            };
            serializedParams = queryString.stringify(params);
        }

        return getProxiedUrl(`${url}${endpoints.token}?${serializedParams}`);
    };

    getAuthorizeUrl = () => {
        const params = createAuthorizeRequestParams(
            this.authConfig,
            this.nonce,
            this.oAuthStateCode
        );
        const serializedParams = queryString.stringify(params);
        const { url, endpoints } = this.authConfig;

        return getProxiedUrl(`${url}${endpoints.authorize}?${serializedParams}`);
    };

    getOAuthStateCode = () => {
        return this.oAuthStateCode;
    };

    async loadIframe(iframeConfig) {
        const iframeData = await iframeUtil.loadIframe(iframeConfig);
        // the iframe returned by PC includes a state parameter in the location
        // we'll store it as a next state/challenge
        this.oAuthStateCode = iframeData.iframeParams.state;
        iframeUtil.checkIframeContent(iframeData, iframeConfig);
        return iframeData;
    }

    requestAccessToken = async ({ onRefreshError, isPreparingLogin = false }) => {
        if (!this.oAuthStateCode) {
            this.oAuthStateCode = generateNonce();
        }
        this.nonce = generateNonce();
        const authorizeUrl = this.getAuthorizeUrl();
        const iframeConfig = {
            src: authorizeUrl,
            expectedSrcPartOnLoad: LOGGED_USER_REDIRECT_URL
        };

        // Some auth solutions will always redirect to the login page unless a
        // parameter is added to the url.
        // If so we attempt to call with the parameter. If that fails (user not logged in)
        // then we will use the url without the parameter (i.e. the failureRedirectUrl)
        if (this.authConfig.silentLoginParam) {
            // call the function again to get a different nonce
            const authorizeUrlWithoutPrompt = `${this.getAuthorizeUrl()}&${this.authConfig.silentLoginParam}`;
            iframeConfig.src = authorizeUrlWithoutPrompt;
            iframeConfig.failureRedirectUrl = authorizeUrl;
        }

        const loadIframeForTokensPromise = this.loadIframe(iframeConfig);

        // waits till the Auth page is loaded
        // in iframe with possible redirect if user is logged in,
        // which in turn triggers post_message with tokens,
        // so the promise waits till the post_message is received as well.
        // After that tokens are assigned
        try {
            // avoid including getTokensFromIframe for prepareLogin
            // causing duplicated token calls
            const iterables = isPreparingLogin ? [loadIframeForTokensPromise]
                : [loadIframeForTokensPromise, this.getTokensFromIframe()];
            const [, oAuthToken] = await Promise.all(iterables);
            this.tokens = oAuthToken;
            /*
              By passing the refresh token param to this function,
              we will use the token endpoint with the grant_type: 'refresh_token'.
              Customer can avoid that by removing the refresh
              token param, the refresh will happen calling authorise followed
               by token with grant_type: 'authorization_code'
            */
            this.scheduleTokenRefreshReq(
                onRefreshError,
                oAuthToken.accessToken,
                oAuthToken.refreshToken
            );
            const userInfo = await this.getUserInfo();
            return {
                res: oAuthToken,
                userInfo: userInfo
            };
        } catch (err) {
            const redirectUrl = this.getAuthorizeUrl(this.oAuthStateCode);
            throw processTokenRequestError(err, redirectUrl);
        }
    };

    requestTokenRefresh = async ({ onRefreshError }, refreshToken, accessToken) => {
        const tokens = await this.tokenPost(accessToken, refreshToken, true);
        const oAuthToken = await getTokensFromHash(this.tokens, tokens, this.authConfig);
        this.tokens = oAuthToken;
        this.scheduleTokenRefreshReq(
            onRefreshError,
            oAuthToken.accessToken,
            oAuthToken.refreshToken
        );
        const userInfo = await this.getUserInfo();
        return {
            res: oAuthToken,
            userInfo: userInfo
        };
    };

    prepareLogin = ({ onRefreshError, isPreparingLogin }) => {
        return this.testForOAuthToken({ onRefreshError, isPreparingLogin });
    };

    testForOAuthToken = ({ onRefreshError, isPreparingLogin }) => {
        return this.requestAccessToken({ onRefreshError, isPreparingLogin });
    };

    waitTokensSet = async () => {
        if (!_.isEmpty(this.tokens)) {
            return this.tokens;
        }
        const tokens = await this.getNewTokens();
        this.tokens = tokens;
        return tokens;
    };
}

// EXPORT
export default (oAuth) => new OAuthUtil(oAuth);
