import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Icon,
    ModalNext,
    Button
} from '@jutro/components';

import { useTranslator } from '@jutro/locale';
import useRepairFacilityMap from '../useRepairFacilityMap';
import RepairFacilityRating from '../RepairFacilitiesRating/RepairFacilitiesRating';
import infoWindowStaticContent, { infoWindowContent } from '../infoWindow/infoWindowContent';
import styles from './RepairFacilityDetails.module.scss';
import messages from './RepairFacilityDetails.messages';

function RepairFacilityDetails(props) {
    const translator = useTranslator();
    const [vendorsList, setVendorsList] = useState([]);
    const {
        onResolve,
        repairFacility,
        goNext,
        lossLocation,
        policyLocation,
        selectCallback,
        getSearchList
    } = props;

    const [selectedFacility, setFacility] = useState({});

    const {
        contactName,
        workNumber,
        emailAddress1,
        score,
        primaryAddress
    } = _.isEmpty(repairFacility) ? selectedFacility : repairFacility;

    const {
        addMarker,
        clearMarkers,
        setMapElement,
        moveMapToCenter,
        mapCenter
    } = useRepairFacilityMap();

    const getAddress = useCallback((addressLine) => {
        if (addressLine) {
            return _.get(primaryAddress, addressLine, '');
        }

        return `${primaryAddress.city}, ${primaryAddress.state} ${primaryAddress.postalCode}`;
    }, [primaryAddress]);

    const goToNextPage = useCallback(() => {
        onResolve();
        goNext();
    }, [onResolve, goNext]);

    const infoWindowCallback = useCallback((facility) => {
        if (selectCallback) {
            selectCallback(facility);
            setFacility(facility);
        }
    }, [selectCallback]);

    useEffect(() => {
        const getResults = async () => {
            const results = await getSearchList(mapCenter);

            setVendorsList(results);
        };

        getResults();
    }, [getSearchList, mapCenter]);

    useEffect(() => {
        clearMarkers();

        let lists = vendorsList;

        if (_.get(repairFacility, 'geocode')) {
            lists = [repairFacility];
        }

        lists.forEach((facility) => {
            const { primaryAddress: { spatialPoint } } = facility;

            addMarker(spatialPoint, 'wrench', infoWindowContent(facility), infoWindowCallback.bind(null, facility));
        });

        const staticLocations = [{ ...lossLocation, type: 'car' }, { ...policyLocation, type: 'home' }];

        staticLocations.forEach((location) => {
            addMarker(_.get(location, 'geocode'), location.type, infoWindowStaticContent(location));
        });

        if (_.get(repairFacility, 'geocode')) {
            moveMapToCenter(_.get(repairFacility, 'primaryAddress.spatialPoint'), infoWindowContent(repairFacility));
        }
    }, [
        addMarker,
        clearMarkers,
        lossLocation,
        policyLocation,
        repairFacility,
        vendorsList,
        infoWindowCallback,
        moveMapToCenter,
        selectedFacility
    ]);

    useEffect(() => {
        setMapElement('mobileMapArea');
    }, [setMapElement]);

    return (
        <div className={styles.repairFacilitySearch}>
            <div className={styles.mobileHeader}>
                <div className={styles.back} role="presentation" onClick={onResolve}>
                    <Icon icon="mi-keyboard-arrow-left" />
                </div>
                <div className={styles.title}>{translator(messages.repairFacilitiesSearch)}</div>
            </div>
            <div className={styles.googleMap}>
                <div id="mobileMapArea" className={styles.mapContainer} />
            </div>
            {
                (_.get(repairFacility, 'geocode') || _.get(selectedFacility, 'geocode')) && (
                    <div className={styles.repairFacilityDetails}>
                        <div className={styles.container}>
                            <div className={styles.detail}>
                                <p className={styles.primaryDetail}>{contactName}</p>
                            </div>
                            <div className={styles.detail}>
                                <p className={styles.secondaryDetail}>{getAddress('addressLine1')}</p>
                                <p className={styles.secondaryDetail}>{getAddress()}</p>
                            </div>
                            <div className={styles.detail}>
                                <p className={styles.secondaryDetail}>{workNumber}</p>
                                <p className={styles.secondaryDetail}>{emailAddress1}</p>
                            </div>
                            <RepairFacilityRating score={score || 0} />
                            <Button
                                className={styles.repairButton}
                                onClick={goToNextPage}
                            >
                                {translator(messages.repairHere)}
                            </Button>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

RepairFacilityDetails.defaultProps = {
    getSearchList: _.noop
};

RepairFacilityDetails.propTypes = {
    onResolve: PropTypes.func.isRequired,
    selectCallback: PropTypes.func.isRequired,
    repairFacility: PropTypes.shape({
        contactName: PropTypes.string,
        workNumber: PropTypes.string,
        emailAddress1: PropTypes.string,
        score: PropTypes.number,
        primaryAddress: PropTypes.shape({})
    }).isRequired,
    goNext: PropTypes.func.isRequired,
    policyLocation: PropTypes.shape({}).isRequired,
    lossLocation: PropTypes.shape({}).isRequired,
    getSearchList: PropTypes.func
};

export function RepairFacilityFullScreenModal(props) {
    const {
        isOpen
    } = props;

    return (
        <ModalNext
            className={styles.fullScreen}
            isOpen={isOpen}
            contentLayout={{
                component: 'div',
                componentProps: {
                    className: styles.fullScreen
                }
            }}
        >
            <RepairFacilityDetails {...props} />
        </ModalNext>
    );
}
RepairFacilityFullScreenModal.propTypes = {
    isOpen: PropTypes.string.isRequired
};

export default RepairFacilityDetails;
