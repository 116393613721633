import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { CheckboxField } from '@jutro/components';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import metadata from './InvoicesTable.metadata.json5';
import styles from './InvoicesTable.module.scss';
import messages from './InvoicesTable.messages';

const getBillStatus = (item, index, property) => {
    if (item.paidStatus === 'fullypaid') {
        return messages.paid;
    }
    return {
        id: `typekey.InvoiceStatus.${item[property.id]}`,
        defaultMessage: item[property.id]
    };
};

const renderFormattedCurrencyField = (item, index) => {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${index}`}
                value={item}
                readOnly
                hideLabel
            />
        </div>
    );
};

const getFormattedCurrency = (item, index, property) => {
    return renderFormattedCurrencyField(item[property.id]);
};

const getCell = (item, index, property) => {
    return item[property.id];
};

function InvoicesTable(props) {
    const {
        invoiceSummary,
        selectedInvoices,
        onUpdateSelectedInvoices,
        showPaidDate,
        showSelect,
        filteredInvoices,
        selectedBilledInvoices,
        setSelectedBilledInvoices
    } = props;

    const intl = useContext(IntlContext);
    const translator = useTranslator();
    const getBillStatus = (item, index, property) => {
        if (item.paidStatus === 'fullypaid') {
             return translator(messages.paid);
        }
        return translator({
            id: `typekey.InvoiceStatus.${item[property.id]}`,
            defaultMessage: item[property.id]
        });
    };

    const getFormattedDate = useCallback((item, index, property) => {
        return intl.formatDate(new Date(new Date(item[property.id]).toLocaleString('en-US', { timeZone: 'America/New_York' })), { year: 'numeric', month: 'short', day: 'numeric' });
    }, [intl]);

    const getCheckboxDataCell = useCallback(
        (item, index, property) => {
            const value = item[property.id];
            const selectedIds = [...selectedInvoices];
            const billedInvoiceIds = [...filteredInvoices.billedInvoiceIds];
            const dueAndPlannedInvoiceIds = [...filteredInvoices.dueAndPlannedInvoiceIds];
            const isChecked = selectedIds.indexOf(value) > -1;
            const isDisabled = dueAndPlannedInvoiceIds.indexOf(value) > -1;
            return (
                <CheckboxField
                    id={`${property.id}_${index}`}
                    name={`${property.id}_${index}`}
                    value={isChecked}
                    disabled={isDisabled}
                    onValueChange={(selected) => {
                        if (selected) {
                            selectedIds.push(value);
                            if (billedInvoiceIds.indexOf(value) > -1) {
                                setSelectedBilledInvoices([...selectedBilledInvoices, value]);
                            }
                            onUpdateSelectedInvoices(selectedIds);
                        } else {
                            const updated = selectedIds.filter((iter) => iter !== value);
                            const billed = selectedBilledInvoices.filter((iter) => iter !== value);
                            if (billedInvoiceIds.indexOf(value) > -1) {
                                setSelectedBilledInvoices(billed);
                            }
                            onUpdateSelectedInvoices(updated);
                        }
                    }}
                />
            );
        },
        [selectedInvoices, selectedBilledInvoices, filteredInvoices,
            onUpdateSelectedInvoices, setSelectedBilledInvoices]
    );

    const expanderContent = (data) => {
        return (
            <>
                <DataTable
                    data={data.invoiceItems}
                    id="invoiceItemsTableId"
                    title="Invoice Items Description"
                    titleId="invoiceItemsTitleId"
                    titlePosition="left"
                    expandable={false}
                    showSearch={false}
                    showPagination={false}
                >
                    <DisplayColumn
                        cell={(row) => row.description}
                        columnProportion={1}
                        header={translator(messages.description)}
                        id="typeHeader"
                        textAlign="left"
                        sortable={false}
                    />
                    <DisplayColumn
                        cell={(row) => getFormattedCurrency(row, 0, {id: 'amount'})}
                        columnProportion={1}
                        header={translator(messages.amount)}
                        id="deductibleHeader"
                        textAlign="left"
                        sortable={false}
                    />
                    <DisplayColumn
                        cell={(row) => row.chargeName}
                        columnProportion={1}
                        header={translator(messages.chargeName)}
                        id="limitTypeHead"
                        textAlign="left"
                        sortable={false}
                    />
                    <DisplayColumn
                        cell={(row) => {
                            return row.invoiceItemHeldStatus ? 'true' : 'false';
                        }}
                        columnProportion={1}
                        header={translator(messages.invoiceItemHeld)}
                        id="limitTypeHead"
                        textAlign="left"
                        sortable={false}
                    />
                </DataTable>
            </>
        );
    };

    const overrideProps = {
        tablePaymentScheduleDetail: {
            data: invoiceSummary,
            // renderExpanderContent: expanderContent,
            visible: invoiceSummary.length > 0
        },
        tablePaymentScheduleDetailLastPaymentDate: {
            visible: showPaidDate
        },
        tablePaymentScheduleDetailPay: {
            visible: showSelect
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getFormattedDate,
            getBillStatus,
            getFormattedCurrency,
            getCell,
            getCheckboxDataCell
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

InvoicesTable.propTypes = {
    invoiceSummary: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedInvoices: PropTypes.arrayOf(PropTypes.string),
    onUpdateSelectedInvoices: PropTypes.func,
    showSelect: PropTypes.bool,
    showPaidDate: PropTypes.bool,
    filteredInvoices: PropTypes.arrayOf(PropTypes.string),
    selectedBilledInvoices: PropTypes.arrayOf(PropTypes.string),
    setSelectedBilledInvoices: PropTypes.func
};

InvoicesTable.defaultProps = {
    selectedInvoices: [],
    onUpdateSelectedInvoices: undefined,
    showSelect: false,
    showPaidDate: false,
    filteredInvoices: [],
    selectedBilledInvoices: [],
    setSelectedBilledInvoices: undefined
};

export default InvoicesTable;
