import React, { useState, useCallback } from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/uiconfig';
import { EntryPageTemplate } from 'gw-components-platform-react';
import { Loader } from '@jutro/components';
import { useAuthentication } from 'gw-digital-auth-react';

import metadata from './EntryPage.metadata.json5';
import styles from './EntryPage.module.scss';
import './EntryPage.messages';

const VIEWS = {
    logInView: 'LOG_IN_VIEW',
    signUpView: 'SIGN_UP_VIEW',
    forgotPasswordView: 'FORGOT_PASSWORD_VIEW'
};

const { applicationDetails, oobloginURL } = appConfig;

function EntryPage({ location }) {
    const { isAuthInProgress, isLoggedIn } = useAuthentication();
    const [view, updateView] = useState(VIEWS.logInView);
    const { state } = location;
    let disableOOTBLogin = false;

    const handleForgotPasswordClick = useCallback(() => {
        updateView(VIEWS.forgotPasswordView);
    }, []);

    const handleSignUpClick = useCallback(() => {
        updateView(VIEWS.signUpView);
    }, []);

    const handleSignInClick = useCallback(() => {
        updateView(VIEWS.logInView);
    }, []);

    // Disable OOTB Login
    const urls = _.find(oobloginURL, (value) => {
        return _.endsWith(window.location.origin, value.url);
    });

    if (!isLoggedIn && (location.pathname && _.endsWith(location.pathname, '/login-page'))
    && (urls === undefined || urls == null)) {
        disableOOTBLogin = true;
    }

    if (isAuthInProgress) {
        return <Loader loaded={!isAuthInProgress} />;
    }

    const overrideProps = {
        gwLoginComponent: {
            successPath: _.get(state, 'from.pathname') || '/home'
        },
        logInViewContainer: {
            visible: view === VIEWS.logInView
        },
        signUpViewContainer: {
            visible: view === VIEWS.signUpView
        },
        forgotPasswordViewContainer: {
            visible: view === VIEWS.forgotPasswordView
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onForgotPasswordClick: handleForgotPasswordClick,
            onSignUpClick: handleSignUpClick,
            onSignInClick: handleSignInClick
        }
    };

    const renderedContentFromMetadata = <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrideProps}
        {...resolvers}
    />;

    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
            disableOOTBLogin={disableOOTBLogin}
        />
    );
}

EntryPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({})
    })
};

EntryPage.defaultProps = {
    location: {}
};

export default EntryPage;
