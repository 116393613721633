import React, {
    useEffect, useState, useMemo, useCallback, useContext
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import {
    Icon, Loader, InfoLabel, Link, DateRangeField
} from '@jutro/components';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { PolicyService } from 'gw-capability-policy';
import { AMPSubmissionDraftService } from 'gw-capability-quoteandbind';
import { DocumentService } from 'gw-capability-policydocument';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { getNormalizedLOBName, isCapabilityEnabled } from 'gw-portals-config-js';
import { useAuthentication } from 'gw-digital-auth-react';
import { LobIconUtil, LocalDateUtil } from 'gw-portals-util-js';
import { generateURL } from 'gw-portals-url-js';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import styles from './LandingPage.module.scss';
import metadata from './LandingPage.metadata.json5';
import messages from './LandingPage.messages';

const getCellType = (rowData) => {
    const activePeriod = _.first(rowData.periods);
    const lineOfBusiness = activePeriod.lines.length > 1
        ? activePeriod.lines.map((line) => line) : _.first(activePeriod.lines);
    const icon = activePeriod.lines.length > 1 ? 'mi-unarchive' : LobIconUtil.getMaterialIcon(lineOfBusiness);
    return <Icon icon={icon} title={lineOfBusiness} key={lineOfBusiness} />;
};

const isPolicyActive = (periods) => {
    const currentRenewed = periods.length > 1;
    const activePeriod = _.first(periods);
    return new Date(activePeriod.effective).getTime() < new Date().getTime() && !currentRenewed;
};

const shouldRenewalNotificationShow = (accountPolicyData) => {
    return accountPolicyData.some((policy) => policy.periods.length > 1);
};

const getCellStatus = (rowData) => {
    if(rowData.isPolicyCancelled){
        return <InfoLabel type="info" size="medium" message={messages.statusCancelled} />;
    }
    else if(rowData.pendingCancellation){
        return <InfoLabel type="info" size="medium" message={messages.statusPendingCancel} />;
    }
    return <InfoLabel type="success" size="medium" message={messages.statusActive} />;
};

const getCellPolicy = (rowData) => {
    const activePeriod = _.first(rowData.periods);
    const periodWithIDCard = rowData.periods.find((policy) => policy.idCardPublicID);
    return (rowData.isPolicyCancelled && rowData.isPendingAudit) ? (activePeriod.policyId)
        : (
            <Link
                className={styles.link}
                to={{
                    pathname: `/account-policy-details/${activePeriod.policyId}`,
                    productCode: activePeriod.lines[0],
                    idCard: periodWithIDCard ? {
                        idCardSessionID: _.get(periodWithIDCard, 'idCardSessionID'),
                        idCardPublicID: _.get(periodWithIDCard, 'idCardPublicID')
                    } : null
                }}
            >
                {activePeriod.policyId}
            </Link>
        );
};


const getCellClaim = (rowData) => {
    const activePeriod = _.first(rowData.periods);

    const redirectPath = {
        pathname: '/fnol-select-policy',
        state: {
            policyNumber: activePeriod.policyId,
            redirectPath: '/home',
            claimData: { lossDate: new Date() }
        }
    };
    return (
        <Link to={redirectPath} className={styles.link}>
            {messages.fileClaim}
        </Link>
    );
};


function LandingPage(props) {
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const localeService = ServiceManager.getService('locale-service');
    const defaultCurrencyCode = localeService.getDefaultCurrencyCode().toUpperCase();
    const [accountBillingData, setAccountBillingData] = useState([]);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const { authHeader } = useAuthentication();
    const [accountData, setAccountData] = useState([]);
    const [isLoading, setLoadingState] = useState(false);
    const { capabilitiesConfig } = appConfig;
    const [currency, setCurrency] = useState(defaultCurrencyCode);



    useEffect(() => {
        if (accountBillingData && accountBillingData.totalDisputedAmount) {
            setCurrency(accountBillingData.totalDisputedAmount.currency);
        }
    }, [accountBillingData]);

    useEffect(() => {
        setLoadingState(true);
        let billingPromise = Promise.resolve([]);
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            billingPromise = AccountBillingDetailsService.getAccountBillingSummary(authHeader);
        }
        Promise.all([billingPromise.then(setAccountBillingData),
            PolicyService.getAccountPolicySummaries(authHeader).then(setAccountPolicyData),
            UsersProfileDetailsService.getAccountsContactSummaries(authHeader).then(setAccountData)
        ]).finally(() => {
            setLoadingState(false);
        });
        // disable re render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCellPremium = (rowData, rowIndex) => {
        const activePeriod = _.first(rowData.periods);
        return rowData.isPendingAudit ? translator(messages.pendingAudit)
            : (
                <CurrencyField
                    id={`premium_${rowIndex}`}
                    value={activePeriod.premium}
                    dataType="object"
                    readOnly
                    hideLabel
                />
            );
    };

    const aggregateBilling = useCallback((invoices) => {
        if (invoices.length === 0) {
            return null;
        }

        let total = 0;
        let due = new Date(invoices[0].dueDate);

        _.each(invoices, (invoice) => {
            total += invoice.amountDue.amount;
            const newDue = new Date(invoice.dueDate);
            if (newDue.getTime() < due.getTime()) {
                due = newDue;
            }
        });

        return {
            amountDue: {
                amount: total,
                currency: invoices[0].amountDue.currency
            },
            earliestDue: due
        };
    }, []);

    const getMyBalanceOverDueAndCurrentData = useCallback(
        (invoiceStatus) => {
            if (accountBillingData.activeInvoices) {
                const invoices = accountBillingData.activeInvoices;
                return aggregateBilling(
                    invoices.filter((invoice) => invoice.invoiceStatus === invoiceStatus)
                );
            }
            return null;
        },
        [accountBillingData.activeInvoices, aggregateBilling]
    );

    const getBalanceData = useCallback(() => {
        const overdue = getMyBalanceOverDueAndCurrentData('due');
        const current = getMyBalanceOverDueAndCurrentData('billed');
        if (overdue && current) {
            return {
                amount: current.amountDue.amount + overdue.amountDue.amount,
                currency: current.amountDue.currency
            };
        }
        if (overdue || current) {
            return (overdue || current).amountDue;
        }
        return null;
    }, [getMyBalanceOverDueAndCurrentData]);

    const getCellEffective = (rowData, rowIndex) => {
        const activePeriod = _.first(rowData.periods);
        const effectiveDate = intl.formatDate(new Date(new Date(activePeriod.effective).toLocaleString('en-US', { timeZone: 'America/New_York' })), { year: 'numeric', month: 'short', day: 'numeric' });
        const expirationDate = intl.formatDate(new Date(new Date(activePeriod.expiration).toLocaleString('en-US', { timeZone: 'America/New_York' })), { year: 'numeric', month: 'short', day: 'numeric' });
        const periodValue = {
            startDate: effectiveDate,
            endDate: expirationDate,
        };
        return (
            <DateRangeField
                id={`effectiveDate_${rowIndex}`}
                value={periodValue}
                hideLabel
                readOnly
            />
        );
    };

    const readValue = useCallback(
        (id, path) => {
            if (path === 'balanceAmount') {
                return getBalanceData();
            }
            if (path === 'overDueAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('due'), 'amountDue');
            }
            if (path === 'currentAmount') {
                return _.get(getMyBalanceOverDueAndCurrentData('billed'), 'amountDue');
            }
            if (path === 'dueDate') {
                const dueDate = _.get(getMyBalanceOverDueAndCurrentData('due'), 'earliestDue');
                return _.isUndefined(dueDate) ? _.get(getMyBalanceOverDueAndCurrentData('billed'), 'earliestDue') : dueDate;
            }
            if (path === 'nextInvoice.dueDate') {
                return intl.formatDate(new Date(new Date(_.get(accountBillingData, path)).toLocaleString('en-US', { timeZone: 'America/New_York' })), { year: 'numeric', month: 'short', day: 'numeric' });
            }
            return _.get(accountBillingData, path);
        },
        [accountBillingData, getBalanceData, getMyBalanceOverDueAndCurrentData]
    );

    const policyWithIDCard = useMemo(() => {
        let periodWithIDCard;
        accountPolicyData.some((policyData) => {
            return policyData.periods.some((period) => {
                if (period.idCardPublicID) {
                    periodWithIDCard = period;
                    return true;
                }
                return false;
            });
        });
        return periodWithIDCard;
    }, [accountPolicyData]);

    const getIDCardQuickLink = useMemo(() => {
        if (!policyWithIDCard) {
            return null;
        }
        return DocumentService.downloadPolicyDocument(
            policyWithIDCard.idCardPublicID,
            policyWithIDCard.idCardSessionID
        );
    }, [policyWithIDCard]);

    const automatedPaymentURL = useMemo(() => {
        const billingID = _.get(accountBillingData, 'manualPayPaymentGroups[0]');

        if (!billingID) {
            return undefined;
        }
        return `billing-summary/${billingID}`;
    }, [accountBillingData]);

    const firstAccountSummaryDefined = useMemo(() => {
        return accountData.length <= 1 && !_.isEmpty(accountData);
    }, [accountData]);

    const mailToTrigger = useCallback((event, email) => {
        event.preventDefault();
        window.location = `mailto:${email}`;
    }, []);

    const claimCapabilityCheck = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'fnol' });
    }, []);

    const onQuoteQuickLinkClick = useCallback(
        (lob) => () => {
            const accountSummary = accountData[0];
            const submission = {
                baseData: {
                    productCode: lob,
                    accountHolder: accountSummary,
                    accountNumber: accountSummary.accountNumber,
                    policyAddress: accountSummary.accountContact.primaryAddress,
                    periodStartDate: LocalDateUtil.today()
                }
            };
            AMPSubmissionDraftService.createForAccount(submission, authHeader).then(
                (savedSubmission) => {
                    const { url } = appConfig.quoteAndBuy;
                    const location = `${url}/quote-${getNormalizedLOBName(lob)}`;
                    const params = {
                        quoteID: savedSubmission.quoteID,
                        postalCode: savedSubmission.baseData.policyAddress.postalCode
                    };

                    window.location = generateURL(location, params);
                }
            );
        },
        [accountData, authHeader]
    );

    const getCellAgent = useCallback(
        (rowData) => {
            return (
                <>
                    <Link className={styles.link} onClick={(e) => mailToTrigger(e, rowData.producerContact.email)} href="/">
                        {rowData.producerContact.displayName}
                    </Link>
                    <br />
                    {rowData.producerContact.phoneNumber ? (
                        <span>
                            {translator(messages.policyDetailsTel)}
                            {rowData.producerContact.phoneNumber}
                        </span>
                    ) : (
                        ''
                    )}
                </>
            );
        },
        [mailToTrigger, translator]
    );

    const getTableOverrides = useCallback(() => {
        return {
            typeColumn: { renderCell: getCellType },
            statusColumn: { renderCell: getCellStatus },
            policyColumn: { renderCell: getCellPolicy },
            effectiveColumn: { renderCell: getCellEffective },
            premiumColumn: { renderCell: getCellPremium },
            fileAClaimColumn: {
                visible: claimCapabilityCheck,
                renderCell: getCellClaim
            },
            agentColumn: {
                visible: accountData.length > 1,
                renderCell: getCellAgent
            }
        };
    }, [accountData, getCellAgent]);

    const onFileAClaimQuickLinkClick = useCallback(
        () => () => {
            const { history } = props;
            return history.push({
                pathname: '/fnol-select-policy',
                state: {
                    redirectPath: '/home',
                    claimData: { lossDate: new Date() }
                }
            });
        },
        [props]
    );

    const handleLinkClick = useCallback(
        (event) => {
            const { history } = props;
            return history.push(event.path);
        },
        [props]
    );

    const showBillingSection = useMemo(() => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' });
    }, []);

    const showContainers = useCallback(() => {
        const filteredData = accountPolicyData.filter(
            (policyData) => policyData.isPendingAudit && policyData.isPolicyCancelled
        );
        return filteredData.length === 0;
    }, [accountPolicyData]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }
    const override = {
        '@field': {
            phone: {
                labelPosition: 'left'
            }
        },
        myBalanceOverdueAmount: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('due'))
        },
        myBalanceCurrentAmount: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('billed'))
        },
        dueDateContainer: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('due'))
        },
        dueDateLabel: {
            visible: !_.isEmpty(getMyBalanceOverDueAndCurrentData('billed'))
        },
        payNowButton: {
            visible: firstAccountSummaryDefined
        },
        mediaSidebar: {
            showContact: accountData.length > 1
        },
        policiesTable: {
            data: accountPolicyData
        },
        printAnIDCardLink: {
            visible: !_.isNil(policyWithIDCard),
            href: getIDCardQuickLink,
            target: '_blank'
        },
        setupAutomatedPaymentsLink: {
            onClick: {
                callback: 'handleLinkClick',
                callbackProps: {
                    path: automatedPaymentURL,
                }
            },
            visible: !!automatedPaymentURL && showBillingSection
        },
        getAPAQuote: {
            onClick: onQuoteQuickLinkClick('PersonalAuto'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        getAHOQuote: {
            onClick: onQuoteQuickLinkClick('Homeowners'),
            visible: appConfig.quoteAndBuy.ampQuote && firstAccountSummaryDefined
        },
        renewalNotification: {
            visible: shouldRenewalNotificationShow(accountPolicyData)
        },
        fileAClaimLink: {
            onClick: onFileAClaimQuickLinkClick(),
            visible: claimCapabilityCheck
        },
        tileGroupContainer: {
            visible: showBillingSection
        },
        makeAPaymentLink: {
            visible: showBillingSection
        },
        cancelledPaymentNotification: {
            visible: accountPolicyData.filter((policy) => policy.isPendingAudit
            && policy.isPolicyCancelled).length > 0
        },
        nextBillContainer: {
            visible: showContainers()
        },
        balanceContainer: {
            visible: showContainers()
        },
        totalDisputedAmountNotification: {
            message: translator(messages.totalDisputedAmountWarningMessage, {
                amount: intl.formatNumber(
                    accountBillingData.totalDisputedAmount
                        ? accountBillingData.totalDisputedAmount.amount : 0,
                    {
                        style: 'currency',
                        currency: currency,
                    }
                ),
                currentDate: intl.formatDate(new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })), { year: 'numeric', month: 'short', day: 'numeric' })
            }),
            visible: accountBillingData.totalDisputedAmount
                && accountBillingData.totalDisputedAmount.amount > 0
        },
        ...getTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveCallbackMap: {
            handleLinkClick
        }
    };

    // eslint-disable-next-line max-len
    return <MetadataContent uiProps={metadata.pageContent} overrideProps={override} {...resolvers} />;
}

export default withRouter(LandingPage);
