import { defineMessages } from 'react-intl';

export default defineMessages({
    searchForTopic: {
        id: 'faq.directive.Search for a topic',
        defaultMessage: 'Search for a topic',
    },
    generalBilling: {
        id: 'faq.sections.General Billing',
        defaultMessage: 'General Billing',
    },
    commentsOrQuestions: {
        id: 'faq.sections.Comments Or Questions',
        defaultMessage: 'Comments/Questions',
    },
    forCertificates: {
        id: 'faq.description.For Certificates',
        defaultMessage: 'For certificates: RA-LI-SWIF-CERTS@PA.GOV',
    },
    forOwnership: {
        id: 'faq.description.For Ownership',
        defaultMessage: 'For changes to ownership: SWIFOWNERSHIP@PA.GOV',
    },
    forCancellation: {
        id: 'faq.description.For Cancellation',
        defaultMessage: 'For cancellations or reinstatements: SWIFCANCEL@PA.GOV',
    },
    forChanges: {
        id: 'faq.description.For Changes',
        defaultMessage: 'For broker changes, address changes, etc.: SWIFCHANGES@PA.GOV',
    },
    forLossRuns: {
        id: 'faq.description.For Loss Runs',
        defaultMessage: 'For loss runs: RA-LISWIF-LOSS-RUNS@PA.GOV',
    },
    claim: {
        id: 'faq.sections.Claim',
        defaultMessage: 'Claim',
    },
    customizeFAQContent: {
        id: 'faq.sections.How to customize the FAQ content',
        defaultMessage: 'How to customize the FAQ content',
    },
    frequentlyAskedQuestions: {
        id: 'faq.directive.Frequently Asked Questions',
        defaultMessage: 'Frequently Asked Questions',
    },
    differentWaysToPay: {
        id: 'faq.topics.payment_methods.question',
        defaultMessage: 'What are the different ways to pay for my insurance policy?',
    },
    creditCardPayment: {
        id: 'faq.topics.payment_methods.link.To make a one time credit card payment, please click here.',
        defaultMessage: 'To make a one time credit card payment, please click here.',
    },
    correctBillingMistake: {
        id: 'faq.topics.billing_mistake.question',
        defaultMessage: 'How can I correct a billing mistake made in the last three months?',
    },
    reportBillingMistake: {
        id: 'faq.topics.billing_mistake.link.To report a billing mistake on your last statement, please click here.',
        defaultMessage: 'To report a billing mistake on your last statement, please click here.',
    },
    claimProcess: {
        id: 'faq.topics.when_to_claim.question',
        defaultMessage: 'When is it best to begin the claim process after something happened to my house?',
    },
    renewlaCostInFuture: {
        id: 'faq.topics.claim_rate_increase.question',
        defaultMessage: 'In the event of a claim against the insurance policy, will my insurance costs increase at renewal or at any time in the future?',
    },
    deductible: {
        id: 'faq.topics.deductible_payment.question',
        defaultMessage: 'Under what circumstances would I have to pay a deductible when making a claim?',
    },
    FaqTopic: {
        id: 'faq.topics.show_video.question',
        defaultMessage: 'How to make a youtube video appear in the answer for a FAQ topic?',
    },
    clickableLink: {
        id: 'faq.topics.show_link.question',
        defaultMessage: 'How can I show a clickable link appear in the answer for a FAQ topic?',
    },
    customText: {
        id: 'faq.topics.show_link_text.question',
        defaultMessage: 'How can I show custom text that can be clicked to access a link in the answer for a FAQ topic?',
    },
    clickedText: {
        id: 'faq.topics.show_link_text.This text can be clicked.',
        defaultMessage: 'This text can be clicked.',
    },
    formattingQuestion: {
        id: 'faq.topics.formatting.question',
        defaultMessage: 'How can I customize the format of the question and answer elements for a given topic?',
    },
    bankTransfer: {
        id: 'faq.topics.payment_methods.answer.long',
        defaultMessage: 'Payments can be made using a major credit/debit card or a bank transfer.',
    },
    helpAccounts: {
        id: 'faq.topics.comments_questions.answer.long',
        defaultMessage: 'Resource accounts are available to help facilitate the day to day operations of the state fund. Please use these accounts when possible for correspondence and requests as follows:',
    },
    payrollChange: {
        id: 'faq.topics.payroll_change.answer.long',
        defaultMessage: 'For changes to payroll, PCRB mods & merit ratings, alternate employers: SWIFPAYROLL@PA.GOV',
    },
    generalQuestions: {
        id: 'faq.topics.general-questions.answer.long',
        defaultMessage: 'Please email ra-li-e-mail-swif@pa.gov for any general questions regarding SWIF. Your email is important to us.  Please note that the SWIF team is working as quickly as possible to review and process your requests.',
    },
    billngMistake: {
        id: 'faq.topics.billing_mistake.answer.long',
        defaultMessage: 'Billing mistakes should be brought to our attention as soon as they are found to ensure that they are promptly corrected. Please make sure to gather as much information about the mistake such as the date and amounts. You may then contact us through email, chat or phone.',
    },
    eventClaim: {
        id: 'faq.topics.when_to_claim.answer.long',
        defaultMessage: 'In order for us to best serve you in time of need, please notify us of any event that may lead to a claim as soon as you can reasonably expect to make a claim against your insurance policy.',
    },
    increasedRates: {
        id: 'faq.topics.claim_rate_increase.answer.long',
        defaultMessage: 'Filing a claim will not necessarily result in increased rates. Several factors are considered when rating a policy renewal.',
    },
    deductibleAmountasedRates: {
        id: 'faq.topics.deductible_payment.answer.long',
        defaultMessage: 'If the policy stipulates a deductible amount, the deductible amount must be covered when making a claim against the policy.',
    },
    navigateToVideo: {
        id: 'faq.topics.show_video.answer.long',
        defaultMessage: 'Using your browser, navigate to the youtube video that you would like to show. Copy the hash part of the url, for example in the following URL https://www.youtube.com/watch?v=XHB609to9dg the video hash is XHB609to9dg. Insert the video hash inside the topic object under a key named videoId.',
    },
    navigateToExternalLink: {
        id: 'faq.topics.show_link.answer.long',
        defaultMessage: 'Using your browser, navigate to the external link that you would like to show. Copy the url from the browser and insert into a new object as the value for the key url.',
    },
    navigateToExternalLinkText: {
        id: 'faq.topics.show_link_text.answer.long',
        defaultMessage: 'Using your browser, navigate to the external link that you would like to show. Copy the url from the browser and insert into a new object as the value for the key url.',
    },
    customFaq: {
        id: 'faq.topics.formatting.answer.long',
        defaultMessage: 'You can use html markup to customize the FAQ such as:&lt;h2&gt;A list example&lt;/h2&gt;&lt;ul&gt;&lt;li&gt;&lt;a href="http://example.com"&gt;The first item is a link&lt;/a&gt;&lt;/li&gt;&lt;li&gt;The second item is  &lt;strong&gt;an emphasis &lt;/strong&gt; &lt;/li&gt; &lt;li&gt;The third item is a spinner image  &lt;img src="throbber.gif" /&gt; &lt;/li&gt; &lt;li&gt;The fourth item is an escape of the tag &lt;div&gt; &lt;/li&gt; &lt;/ul&gt;',
    },
    typeQuestion: {
        id: 'faq.directive.Start typing your question...',
        defaultMessage: 'Start typing your question...',
    },
    sections: {
        id: 'faq.directive.Sections',
        defaultMessage: 'Sections',
    },
    noMatchingError: {
        id: 'faq.directive.No matching results found',
        defaultMessage: 'No matching results found',
    },
    aboutThePortal: {
        id: 'faq.sections.About the Portal',
        defaultMessage: 'About the Portal',
    },
    purposeOfClaimPortal: {
        id: 'faq.topics.about_portal_for_ph.inline_question',
        defaultMessage: 'What is the purpose of the Claim Portal for Policy Holders?',
    },
    purposeOfClaimPortalQuestion: {
        id: 'faq.topics.about_portal_for_ph.question',
        defaultMessage: 'What is the purpose of the Claim Portal for Policy Holders?',
    },
    purposeOfClaimPortalShortAnswer: {
        id: 'faq.topics.about_portal_for_ph.answer.short',
        defaultMessage: 'Claim Portal for Policyholders can be used to file a claim against a policy based on one or more lines of business',
    },
    purposeOfClaimPortalLongAnswer: {
        id: 'faq.topics.about_portal_for_ph.answer.long',
        defaultMessage: 'Claim Portal for Policyholders can be used to file a claim against a policy based on one or more lines of business.',
    },
    purposeOfClaimPortalProducer: {
        id: 'faq.topics.about_portal_for_producers.inline_question',
        defaultMessage: 'What is the purpose of the Claim Portal for Agents?',
    },
    purposeOfClaimPortalQuestionProducer: {
        id: 'faq.topics.about_portal_for_producers.question',
        defaultMessage: 'What is the purpose of the Claim Portal for Agents?',
    },
    purposeOfClaimPortalShortAnswerProducer: {
        id: 'faq.topics.about_portal_for_producers.answer.short',
        defaultMessage: 'The Claim Portal for Agents is intended for agents that can act on behalf of the insured.',
    },
    purposeOfClaimPortalLongAnswerProducer: {
        id: 'faq.topics.about_portal_for_producers.answer.long',
        defaultMessage: 'The Claim Portal for Agents is intended for agents that can act on behalf of the insured and perform actions like filing a new claim or verifying the status of an existing one.',
    },
    creditCardPayments: {
        id: 'faq.sections.CreditCardPayments',
        defaultMessage: 'Credit Card Payments',
    },
    ifIDontPayQuestion: {
        id: 'faq.topics.if-i-dont-pay.question',
        defaultMessage: '<strong>What happens if I don t pay my Worker s Compensation bill?</strong>',
    },
    ifIDontPay: {
        id: 'faq.topics.if-i-dont-pay.answer.long',
        defaultMessage: 'If you do not pay your balance due with the State Workers  Insurance Fund (SWIF) by your billing due date, your account will go into collections. A set of Collection letters will go out allowing you 30 days to make your payment to SWIF. If. at that point, no payment or communication is made on your account, the account will then be sent to the Pennsylvania Office of Attorney General (OAG).',
    },
    commonWealthAttorneys: {
        id: 'faq.topics.common-wealth-attorneys.answer.long',
        defaultMessage: 'Commonwealth Attorneys Act, 71 P.S. Section 732-204(c) states:  The Attorney General shall collect, by suit or otherwise, all debts, taxes and accounts due the Commonwealth which shall be referred to and placed with the Attorney General for collection by any Commonwealth Agency; the Attorney General shall keep a proper docket or dockets, duly indexed, of all such claims, showing whether they are in litigation and their nature and condition. ',
    },
    managementDirective: {
        id: 'faq.topics.management-directive.answer.long',
        defaultMessage: 'This statute is what allows us to refer accounts to the OAG, but it is the Management Directive 310.10 that sets forth the procedure that can be used by an agency to collect and refer accounts to the OAG s office. Management Directive 310.10 states its purpose:  To establish policy and guidelines for collection accounts due to the Commonwealth, and to provide procedures for depositing collections made by the Office of Attorney General, Financial Enforcement Section, and for billing state agencies for collection expenses.',
    },
    cancelledPolicyPaymentQuestion: {
        id: 'faq.topics.cancelled-policy-payment.question',
        defaultMessage: '<strong>Can I make a payment on a Cancelled policy?</strong>',
    },
    cancelledPolicyPayment: {
        id: 'faq.topics.cancelled-policy-payment.answer.long',
        defaultMessage: 'No, if you need to make a payment on a cancelled policy, call 570-614-1543 or send an email to RA-LISWIFCOLLECT@PA.GOV with your contact information for a return call to process your payment.',
    },
    makeLowerPaymentQuestion: {
        id: 'faq.topics.make-lower-payment.question',
        defaultMessage: '<strong>Can I make a payment lower than the amount that I owe?</strong>',
    },
    makeLowerPayment: {
        id: 'faq.topics.make-lower-payment.answer.long',
        defaultMessage: 'No, but you can call the Collections Department to discuss payment options',
    },
    makeLowerPaymentOption1: {
        id: 'faq.topics.make-lower-payment.option1.long',
        defaultMessage: '(570) 614-1543',
    },
    makeLowerPaymentOption2: {
        id: 'faq.topics.make-lower-payment.option2.long',
        defaultMessage: '(570) 941-1678',
    },
    makeLowerPaymentOption3: {
        id: 'faq.topics.make-lower-payment.option3.long',
        defaultMessage: 'Email: RA-LISWIFCOLLECT@PA.GOV',
    },
    makeLowerPaymentOption4: {
        id: 'faq.topics.make-lower-payment.option4.long',
        defaultMessage: 'Fax: (717) 525-5140',
    },
    twoCardsTransactionQuestion: {
        id: 'faq.topics.two-cards-transaction.question',
        defaultMessage: '<strong>Can the insured use two credit cards for the same transaction?</strong>',
    },
    twoCardsTransaction: {
        id: 'faq.topics.two-cards-transaction.answer.long',
        defaultMessage: 'Yes, however the insured would need to call the Collections Department to initiate those transactions. The insured would also be responsible for two convenience fees, as they would need to complete two separate transactions.',
    },
    cannotUseCreditCardQuestion: {
        id: 'faq.topics.cannot-use-credit-card.question',
        defaultMessage: '<strong>What policies cannot pay by credit card via the customer portal?</strong>',
    },
    cannotUseCreditCardOption1: {
        id: 'faq.topics.cannot-use-credit-card.option1.long',
        defaultMessage: 'New submission/application deposits',
    },
    cannotUseCreditCardOption2: {
        id: 'faq.topics.cannot-use-credit-card.option2.long',
        defaultMessage: 'Reinstatements',
    },
    cannotUseCreditCardOption3: {
        id: 'faq.topics.cannot-use-credit-card.option3.long',
        defaultMessage: 'Cancelled policies with pending final audits ',
    },
    creditCardPaymentStepsQuestion: {
        id: 'faq.topics.credit-card-payment-steps.question',
        defaultMessage: '<strong>What is required to make the credit card payment?</strong>',
    },
    creditCardPaymentSteps: {
        id: 'faq.topics.credit-card-payment-steps.answer.long',
        defaultMessage: 'Prior to submitting a payment, we strongly recommended that you contact your credit card issuing company. Transactions for higher dollar amounts are most likely to be declined. This will keep you from repeating the online payment process.',
    },
    creditCardPaymentSteps1: {
        id: 'faq.topics.credit-card-payment-steps1.long',
        defaultMessage: 'Credit card number - VISA, MasterCard, Discover and American Express are accepted',
    },
    creditCardPaymentSteps2: {
        id: 'faq.topics.credit-card-payment-steps2.long',
        defaultMessage: 'Cardholder Name',
    },
    creditCardPaymentSteps3: {
        id: 'faq.topics.credit-card-payment-steps3.long',
        defaultMessage: 'Credit card billing address',
    },
    creditCardPaymentSteps4: {
        id: 'faq.topics.credit-card-payment-steps4.long',
        defaultMessage: "The credit card's security code and expiration date",
    },
    creditCardPaymentSteps5: {
        id: 'faq.topics.credit-card-payment-steps5.long',
        defaultMessage: 'Email for payment confirmation',
    },
    feeAndProcessingTimesQuestion: {
        id: 'faq.topics.fee-and-processing-times.question',
        defaultMessage: '<strong>Fee and processing times?</strong>',
    },
    feeAndProcessingTimes: {
        id: 'faq.topics.fee-and-processing-times.answer.long',
        defaultMessage: 'A $20.00 convenience fee will be added to your total at the end of your transaction before checkout. This fee is non-refundable. Once your transaction is completed, your credit card will be charged that day, and you will receive an email notification of your transaction. Please allow up to three (3) business days for online payments to be reflected on your account balance. All refunds will be issued by check. Please note that refund requests may take up to 10 days for processing.',
    },
    disclaimerNote: {
        id: 'faq.topics.disclaimer-note.long',
        defaultMessage: '<i>* Please note that credit card information will not be saved or stored. Once the transaction has been processed and completed, all personal information is discarded.</i>',
    },
    accountTurnedToOfficeQuestion: {
        id: 'faq.topics.account-turned-to-office.question',
        defaultMessage: '<strong>Can I still make a payment if my account has been turned over to the Office of the Attorney General for collection?</strong>',
    },
    accountTurnedToOffice: {
        id: 'faq.topics.account-turned-to-office.answer.long',
        defaultMessage: 'Yes, SWIF will accept the payment and notify the Office of the Attorney General.',
    }
});
