import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import CoverageTermsIterableComponent from '../../CoverageTermsIterableComponent/CoverageTermsIterableComponent';
import metadata from './HomeCoverageTable.metadata.json5';
import messages from './HomeCoverageTable.messages';
import styles from './HomeCoverageTable.module.scss';

function HomeCoverageTable(props) {
    const { value: hoCoverages } = props;
    const translator = useTranslator();

    const generateScheduledItemsCoverageOverrides = useCallback(() => {
        const overrides = hoCoverages.scheduledItems.map((coverage, cindex) => {
            return {
                [`scheduledItemsCoverageTerm${cindex}`]: {
                    label: coverage.description,
                    value: coverage.value
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [hoCoverages.scheduledItems]);

    const overrides = {
        '@field': {
            readOnly: true
        },
        sectionICoverables: {
            visible: !_.isEmpty(hoCoverages.sectionICoverages)
        },
        sectionIICoverables: {
            visible: !_.isEmpty(hoCoverages.sectionIICoverages)
        },
        additionalLiabilityCoverables: {
            visible: !_.isEmpty(hoCoverages.additionalLiabilityCoverages)
        },
        additionalPropertyCoverables: {
            visible: !_.isEmpty(hoCoverages.additionalPropertyCoverages)
        },
        scheduledItemsCoverables: {
            visible: !_.isEmpty(hoCoverages.scheduledItems)
        },
        sectionICoverableTitle: {
            value: !_.isEmpty(hoCoverages.sectionICoverages)
                ? hoCoverages.sectionICoverages[0].coverageCategory
                : undefined
        },
        sectionIICoverableTitle: {
            value: !_.isEmpty(hoCoverages.sectionIICoverages)
                ? hoCoverages.sectionIICoverages[0].coverageCategory
                : undefined
        },
        additionalLiabilityCoverableTitle: {
            value: !_.isEmpty(hoCoverages.additionalLiabilityCoverages)
                ? hoCoverages.additionalLiabilityCoverages[0].coverageCategory
                : undefined
        },
        additionalPropertyCoverableTitle: {
            value: !_.isEmpty(hoCoverages.additionalPropertyCoverages)
                ? hoCoverages.additionalPropertyCoverages[0].coverageCategory
                : undefined
        },
        scheduledItemsCoverableTitle: {
            value: !_.isEmpty(hoCoverages.scheduledItems)
                ? translator(messages.scheduledItems)
                : undefined
        },
        ...generateScheduledItemsCoverageOverrides()
    };

    const resolvers = {
        resolveComponentMap: {
            coveragetermsiterablecomponent: CoverageTermsIterableComponent
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, hoCoverages, id, path, overrides);
        },
        [hoCoverages, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={hoCoverages}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

HomeCoverageTable.propTypes = {
    value: PropTypes.shape({
        scheduledItems: PropTypes.arrayOf(PropTypes.shape({})),
        sectionICoverages: PropTypes.arrayOf(PropTypes.shape({})),
        sectionIICoverages: PropTypes.arrayOf(PropTypes.shape({})),
        additionalLiabilityCoverages: PropTypes.arrayOf(PropTypes.shape({})),
        additionalPropertyCoverages: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
};

export default HomeCoverageTable;
