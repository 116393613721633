import { defineMessages } from 'react-intl';

export default defineMessages({
    searchingForRepairFacilities: {
        id: 'fnol.pa.components.repair-facilities-list.Searching for repair facilities in this area...',
        defaultMessage: 'Searching for repair facilities in this area...'
    },
    noRecommendedRepair: {
        id: 'fnol.pa.components.repair-facilities-list.No recommended repair facilities in this area.',
        defaultMessage: 'No recommended repair facilities in this area.'
    },
    pleaseTryDifferentLocation: {
        id: 'fnol.pa.components.repair-facilities-list.Please try a different location.',
        defaultMessage: 'Please try a different location.'
    },
    map: {
        id: 'fnol.pa.components.map-type-control.Map',
        defaultMessage: 'Map'
    },
    satellite: {
        id: 'fnol.pa.components.map-type-control.Satellite',
        defaultMessage: 'Satellite'
    },
    searchOnMap: {
        id: 'fnol.pa.components.repair-facility-selector.Search on map',
        defaultMessage: 'Search on map'
    },
    closestRepairFacilities: {
        id: 'fnol.pa.components.repair-facilities-search-option.Closest Repair Facilities',
        defaultMessage: 'Closest Repair Facilities'
    }
});
