import React, {
    useContext, useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
    withModalContext, Loader, useModal, ModalNext, ModalHeader, ModalBody, ModalFooter, Button
} from '@jutro/components';
// import { PdfCustomModal } from 'gw-capability-enrollment-react';
// import { PDFViewer } from 'gw-document-pdfdisplay-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { MetadataForm } from '@jutro/uiconfig';

import { withAuthenticationContext } from 'gw-digital-auth-react';
import { getNormalizedLOBName } from 'gw-portals-config-js';
import { useDependencies } from 'gw-portals-dependency-react';
import { PaymentComponent } from 'gw-components-platform-react';
import { messages as commonMessages } from 'gw-platform-translations';

import metadata from './BillingSummary.metadata.json5';
import styles from './BillingSummary.module.scss';
import messages from './BillingSummary.messages';

const policyLevelHeaderStrings = {
    pa: {
        autoPaymentEnabled: messages.personalAutoPolicyAutomaticPaymentsEnabled,
        default: messages.personalAutoPolicy
    },
    ho: {
        autoPaymentEnabled: messages.homeownersPolicyAutomaticPaymentsEnabled,
        default: messages.homeownersPolicy
    },
    bop: {
        autoPaymentEnabled: messages.businessOwnersPolicyAutomaticPaymentsEnabled,
        default: messages.businessOwnersPolicy
    },
    ca: {
        autoPaymentEnabled: messages.commercialAutoPolicyAutomaticPaymentsEnabled,
        default: messages.commercialAutoPolicy
    },
    cp: {
        autoPaymentEnabled: messages.commercialPropertyPolicyAutomaticPaymentsEnabled,
        default: messages.commercialPropertyPolicy
    },
    cpkg: {
        autoPaymentEnabled: messages.commercialPackagePolicyAutomaticPaymentsEnabled,
        default: messages.commercialPackagePolicy
    },
    gl: {
        autoPaymentEnabled: messages.generalLiabilityPolicyAutomaticPaymentsEnabled,
        default: messages.generalLiabilityPolicy
    },
    im: {
        autoPaymentEnabled: messages.inlandMarinePolicyAutomaticPaymentsEnabled,
        default: messages.inlandMarinePolicy
    },
    wc: {
        autoPaymentEnabled: messages.workersCompPolicyAutomaticPaymentsEnabled,
        default: messages.workersCompPolicy
    },
    wc7: {
        autoPaymentEnabled: messages.workersComp7PolicyAutomaticPaymentsEnabled,
        default: messages.workersComp7Policy
    },
    default: {
        autoPaymentEnabled: messages.policyAutomaticPaymentsEnabled,
        default: messages.policy
    }
};

const getAnyPaidInvoices = (invoiceSummary) => {
    return _.filter(invoiceSummary, (item) => {
        const { amount } = item.totalPaid;
        return item.paidStatus === 'fullypaid' || amount !== 0;
    });
};

const getUnpaidInvoices = (invoiceSummary) => {
    return _.filter(invoiceSummary, (item) => item.paidStatus !== 'fullypaid');
};

const hasUnpaidInvoices = (invoiceSummary) => {
    const unpaidInvoices = getUnpaidInvoices(invoiceSummary);
    return unpaidInvoices.length > 0;
};

const createPaymentInstrumentObject = (paymentDetailsVm, paymentMethod) => {
    const paymentDetailsValue = paymentDetailsVm.value;

    const bankAccountData = {
        ...paymentDetailsValue
    };

    const creditCardData = {
        ...paymentDetailsValue
    };

    let detailsValue;
    if (paymentMethod === 'wire') {
        detailsValue = { bankAccountData };
    } else {
        detailsValue = { creditCardData };
    }

    const paymentInstrumentValue = {
        paymentMethod,
        ...detailsValue
    };

    return paymentInstrumentValue;
};

const getPolicyLevelInvoiceStreamHeader = (lobCode, isAutoPaymentEnabled) => {
    const normalizedLOBName = getNormalizedLOBName(lobCode);
    const lobHeader = _.has(policyLevelHeaderStrings, normalizedLOBName)
        ? policyLevelHeaderStrings[normalizedLOBName]
        : policyLevelHeaderStrings.default;

    if (isAutoPaymentEnabled) {
        return lobHeader.autoPaymentEnabled;
    }

    return lobHeader.default;
};

const getInvoiceStreamHeader = (isAccLevelStream, paymentGroupDetails, lobCode) => {
    let header = '';
    const paymentInstrumentSummary = paymentGroupDetails
        ? paymentGroupDetails.paymentInstrumentSummary
        : null;
    const isAutomaticPaymentEnabled = paymentInstrumentSummary != null;
    if (isAccLevelStream) {
        header = isAutomaticPaymentEnabled
            ? messages.invoicesAutomaticPaymentsEnabled
            : messages.invoices;
    } else {
        header = getPolicyLevelInvoiceStreamHeader(lobCode, isAutomaticPaymentEnabled);
    }
    return header;
};

function BillingSummary(props) {
    const {
        showAlert, showModal
    } = useModal();

    const {
        invoiceStreamId,
        policyNumber,
        accountLevelBilling,
        lobCode,
        paymentGroupDetails,
        currency,
        policySummaries,
        authHeader,
        reloadInvoiceDetails,
        updateShowPayeezyIframe
    } = props;

    const intl = useContext(IntlContext);
    const translator = useTranslator();

    const { AccountBillingDetailsService: BillingService } = useDependencies(
        'AccountBillingDetailsService'
    );
    const { BillingService: PolicyBillingService } = useDependencies(
        'BillingService'
    );
    const { PolicyService } = useDependencies('PolicyService');

    // screen states
    const [isLoadingData, updateIsLoadingData] = useState(false);
    const [selectedInvoicesForPayment, updateSelectedInvoicesForPayment] = useState([]);
    const [selectedInvoiceFilter, updateSelectedInvoiceFilter] = useState('planned');
    const [selectedScreen, updateSelectedScreen] = useState('default');
    const [manualAmountToPay, updateManualAmount] = useState(null);
    const [selectedInvoiceAmount, updateSelectedInvoiceAmount] = useState(0);
    const [isTnCSelected, updateTnCSelection] = useState(false);
    const [showPendingCancellationMessage, setShowPendingCancellationMessage] = useState(false);
    const [filteredInvoices, setfilteredInvoices] = useState({
        billedInvoiceIds: [],
        dueInvoiceIds: []
    });
    const [dueInvoices, setDueInvoices] = useState([]);
    const [selectedBilledInvoices, setSelectedBilledInvoices] = useState([]);
    const payingAmountValue = manualAmountToPay ? manualAmountToPay.amount : 0;
    const [selectedPolicyDetails, setSelectedPolicyDetails] = useState();
    const {
        invoiceSummary = [],
        relatedPolicies = [],
        paymentInstrument,
        paymentInstrumentSummary,
        periodicity,
        disputedAmount
    } = paymentGroupDetails;

    useEffect(() => {
        PolicyService.getPolicyPendingCancellationDetails(policyNumber, authHeader).then(
            setShowPendingCancellationMessage
        );
        const policyDetails = policySummaries.filter((value) => {
            if (value.periods[0].policyId === policyNumber) {
                return value;
            }
        });

        if (policyDetails?.length > 0) {
            setSelectedPolicyDetails(policyDetails);
        }
    }, [PolicyService, policyNumber, authHeader]);

    const firstInvoiceDueDate = useMemo(() => {
        const unPaidInvoices = _.filter(invoiceSummary, (invoice) => {
            return invoice.paidStatus !== 'paid';
        });

        const firstUnpaidInvoice = _.minBy(unPaidInvoices, (invoice) => {
            return new Date(invoice.dueDate).getTime();
        });

        return firstUnpaidInvoice ? firstUnpaidInvoice.dueDate : null;
    }, [invoiceSummary]);

    const resetInternalStates = useCallback(() => {
        updateIsLoadingData(true);
        reloadInvoiceDetails(invoiceStreamId, true).finally(() => {
            updateSelectedInvoicesForPayment([]);
            updateSelectedInvoiceFilter('planned');
            updateManualAmount(null);
            updateIsLoadingData(false);
        });
    }, [reloadInvoiceDetails, invoiceStreamId]);

    const onClickConfirmMakePayment = useCallback(
        () => {
            PolicyBillingService.getPaymentInformation(
                policyNumber,
                manualAmountToPay.amount,
                authHeader
            ).then((paymentInformation) => {
                const componentProps = {
                    paymentInformation: paymentInformation
                };
                showModal(
                    <PaymentConfirmationPopup {...componentProps} />
                ).then((results) => {
                    return results;
                }).catch(_.noop);
            })
                .catch(() => {
                    showAlert({
                        title: messages.paymentRequestFailed,
                        message: messages.sorryYourPaymentCouldNotBeProcessedAtThisTime,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).then(() => {
                        updateManualAmount(null);
                    }, _.noop);
                });
        },
        [PolicyBillingService, manualAmountToPay, authHeader, updateManualAmount, policyNumber]
    );

    function PaymentConfirmationPopup(newProps) {
        const {
            onReject,
            onResolve,
            isOpen,
            paymentInformation
        } = newProps;

        const handleResolve = () => {
            updateShowPayeezyIframe(true);
            BillingService.createPaymentReportRecord(policyNumber, authHeader)
                .catch(_.noop);
            setTimeout(() => {
                onResolve();
            }, 1000);
            return true;
        };
        const handleReject = () => {
            BillingService.createPaymentReportRecord(policyNumber, authHeader)
                .catch(_.noop);
            onReject();
        };
        const time = new Date().getTime();

        return (
            <ModalNext isOpen={isOpen}>
                <form action={paymentInformation.url} method="post" target="payeezy_page" onSubmit={handleResolve}>
                    <ModalHeader title="Payment Confirmation" />
                    <ModalBody>
                        <>
                            <p>
                                {translator(messages.payeezyServiceFeeMessage)}
                            </p>
                            <p>
                                {translator(messages.payeezyAttentionMessage)}
                            </p>
                            <p>
                                {translator(messages.payeezyCreditCardPaymentAlternatives)}
                            </p>
                            <p>
                                {translator(messages.paymentAlternativesContactInformation)}
                            </p>
                            <input type="hidden" name="x_login" value={paymentInformation.xlogin} />
                            <input type="hidden" name="x_fp_sequence" value={paymentInformation.sequence} />
                            <input type="hidden" name="x_fp_timestamp" value={paymentInformation.timeStamp} />
                            <input type="hidden" name="x_amount" value={paymentInformation.totalAmountToPay} />
                            <input type="hidden" name="x_fee_amount" value={paymentInformation.convenienceFee.amount} />
                            <input type="hidden" name="x_fp_hash" value={paymentInformation.hash} size="40" />
                            <input type="hidden" name="x_show_form" value="PAYMENT_FORM" />
                            <input type="hidden" name="Customer_Ref" value={`P${paymentInformation.customerRef}`} />
                            <input type="hidden" name="x_invoice_num" value={`P${paymentInformation.customerRef}`} />
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleReject} type="secondary">
                            Cancel
                        </Button>
                        <Button actionType="submit">
                            Confirm
                        </Button>
                    </ModalFooter>
                </form>
            </ModalNext>
        );
    }

    const triggerModal = useCallback(() => {
        showModal(
            /*   <PdfCustomModal message="" confirmButtonText="Close" cancelButtonText="Disagree">
                <PDFViewer isPaymentTnC />
            </PdfCustomModal> */
        ).catch(_.noop);
    }, []);

    const onClickCancelMakePayment = useCallback(() => {
        BillingService.createPaymentReportRecord(policyNumber, authHeader)
            .catch(_.noop);
        updateSelectedScreen();
    }, []);

    const onClickConfirmSetupAutomaticPayment = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentDetailsValue = paymentDetailsVm.value;

            const bankAccountData = {
                ...paymentDetailsValue
            };

            const creditCardData = {
                ...paymentDetailsValue
            };

            let detailsValue;
            if (paymentMethod === 'wire') {
                detailsValue = { bankAccountData };
            } else {
                detailsValue = { creditCardData };
            }

            const paymentInstrumentValue = {
                paymentMethod,
                ...detailsValue
            };

            BillingService.setPaymentGroupPaymentInformation(
                invoiceStreamId,
                paymentInstrumentValue,
                authHeader
            )
                .then(() => {
                    updateSelectedScreen('automaticConfirmation');
                })
                .catch(() => {
                    showAlert({
                        title: messages.accountUpdateFailed,
                        message: messages.sorryWeWereNotAbleToSetupAutomaticPaymentsOnYourAccount,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).then(() => {
                        resetInternalStates();
                    }, _.noop);
                });
        },
        [BillingService, invoiceStreamId, authHeader, resetInternalStates]
    );

    const onClickCancelSetupAutomaticPayment = useCallback(() => {
        updateSelectedScreen();
    }, []);

    const onClickConfirmChangePaymentSource = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentInstrumentValue = createPaymentInstrumentObject(
                paymentDetailsVm,
                paymentMethod
            );

            BillingService.setPaymentGroupPaymentInformation(
                invoiceStreamId,
                paymentInstrumentValue,
                authHeader
            )
                .then(() => {
                    updateSelectedScreen('paymentSourceConfirmation');
                })
                .catch(() => {
                    showAlert({
                        title: messages.changingPaymentMethod,
                        message: messages.thereWasAProblemChangingThePaymentMethod,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).then(() => {
                        resetInternalStates();
                    }, _.noop);
                });
        },
        [BillingService, invoiceStreamId, authHeader, resetInternalStates]
    );

    const onClickCancelChangePaymentSource = useCallback(() => {
        updateSelectedScreen();
    }, []);

    const onUpdateSelectedInvoices = useCallback(
        (selectedInvoiceSummariesId) => {
            const selectedInvoiceObjects = _.filter(invoiceSummary, (anInvoiceSummary) => {
                return selectedInvoiceSummariesId.includes(anInvoiceSummary.invoiceId);
            });

            const selectedTotalAmount = _.sumBy(selectedInvoiceObjects, (invoice) => {
                return Number(_.get(invoice, 'amountDue.amount'));
            }).toFixed(2);

            updateSelectedInvoiceAmount(selectedTotalAmount);
            const amountObject = {
                amount: selectedTotalAmount,
                currency: currency
            };

            updateManualAmount(amountObject);
            updateSelectedInvoicesForPayment(selectedInvoiceSummariesId);
        },
        [invoiceSummary, currency]
    );

    useEffect(() => {
        const plannedInvoiceIds = [];
        const selectedInvoiceIds = [];
        const billedInvoiceIds = [];
        _.filter(invoiceSummary, (invoice) => {
            if (invoice.invoiceStatus === 'planned') {
                plannedInvoiceIds.push(invoice.invoiceId);
            } else if (invoice.invoiceStatus === 'due') {
                selectedInvoiceIds.push(invoice.invoiceId);
            } else if (invoice.invoiceStatus === 'billed') {
                billedInvoiceIds.push(invoice.invoiceId);
            }
        });
        setDueInvoices(selectedInvoiceIds);
        setfilteredInvoices({
            billedInvoiceIds: billedInvoiceIds,
            dueAndPlannedInvoiceIds: [...plannedInvoiceIds, ...selectedInvoiceIds]
        });
        onUpdateSelectedInvoices(selectedInvoiceIds);
    }, [invoiceSummary, onUpdateSelectedInvoices]);

    const onClickMenuLink = useCallback(
        (evt) => {
            const { id: menuLinkId } = evt.currentTarget;
            let screen;
            switch (menuLinkId) {
                case 'makeAPaymentLinkId':
                    screen = 'payment';
                    break;
                case 'setupAutomaticPaymentsLinkId':
                    screen = 'automatic';
                    break;
                case 'changePaymentMethodLinkId':
                    screen = 'payment-source';
                    break;
                case 'viewPoliciesLinkId':
                    screen = 'policy-list';
                    break;
                case 'viewInvoicesLinkId':
                case 'default':
                    screen = 'default';
                    break;
                default:
                    screen = 'default';
                    resetInternalStates();
                    break;
            }
            updateSelectedScreen(screen);
        },
        [resetInternalStates]
    );

    const onClickFilterChange = useCallback((value) => {
        updateSelectedInvoiceFilter(value);
    }, []);

    const isAmountToPayValid = useCallback((amount) => {
        return amount === 0 || _.isNull(amount);
    }, [selectedInvoiceAmount]);

    useEffect(() => {
        const defaultFilter = hasUnpaidInvoices(invoiceSummary) ? 'planned' : 'paid';
        if (invoiceSummary.length > 0) updateSelectedInvoiceFilter(defaultFilter);
    }, [invoiceSummary]);

    if (isLoadingData) {
        return <Loader loaded={!isLoadingData} />;
    }

    const handleTnCSelection = useCallback(() => {
        updateTnCSelection(!isTnCSelected);
    }, [isTnCSelected]);

    const checkInvoicesSelected = () => {
        let showError = false;
        const billedInvoices = filteredInvoices.billedInvoiceIds;
        if (billedInvoices.length === 0) {
            showError = false;
        } else if (dueInvoices.length !== 0
                && (billedInvoices.length !== 0 && selectedBilledInvoices.length === 0)
                && selectedInvoiceAmount < manualAmountToPay.amount) {
            showError = true;
        }
        return showError;
    };

    const disablePayNow = useCallback(() => {
        const isAmountError = (manualAmountToPay != null && selectedBilledInvoices.length === 0
            && (manualAmountToPay.amount < selectedInvoiceAmount
            || checkInvoicesSelected()))
            || (manualAmountToPay != null && selectedBilledInvoices.length > 0
            && manualAmountToPay.amount < selectedInvoiceAmount);
        return selectedInvoicesForPayment.length === 0 || !isTnCSelected || isAmountError;
    }, [manualAmountToPay, isTnCSelected, selectedInvoiceAmount,
        selectedBilledInvoices, selectedInvoicesForPayment]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        menuLinkContainerId: {
            visible: (invoiceStreamId || accountLevelBilling) && !(selectedScreen === 'automaticConfirmation')
        },
        makeAPaymentLinkId: {
            visible: !paymentInstrumentSummary && hasUnpaidInvoices(invoiceSummary)
        },
        viewPoliciesLinkId: {
            visible: accountLevelBilling
        },
        changePaymentMethodLinkId: {
            visible: !!paymentInstrumentSummary
        },
        setupAutomaticPaymentsLinkId: {
            visible: !paymentInstrumentSummary
        },
        billingInvoicesContainerId: {
            visible: selectedScreen === 'default' && !!invoiceStreamId
        },
        billingInvoicesHeaderId: {
            content: `${translator(getInvoiceStreamHeader(accountLevelBilling, paymentGroupDetails, lobCode))} - ${paymentGroupDetails.pniname}`,
            visible: invoiceStreamId || accountLevelBilling
        },
        makePaymentInvoicesTableTitleId: {
            content: `${translator(messages.selectInvoicesToPay)} - ${paymentGroupDetails.pniname}`,
            visible: invoiceStreamId || accountLevelBilling
        },
        invoicesFilterToggleId: {
            onValueChange: onClickFilterChange
        },
        billingInvoicesComponentId: {
            invoiceSummary:
                selectedInvoiceFilter === 'planned'
                    ? getUnpaidInvoices(invoiceSummary)
                    : getAnyPaidInvoices(invoiceSummary),
            showPaidDate: selectedInvoiceFilter === 'paid'
        },
        makePaymentContainerId: {
            visible: selectedScreen === 'payment'
        },
        makePaymentInvoicesTableComponentId: {
            invoiceSummary: getUnpaidInvoices(invoiceSummary),
            selectedInvoices: selectedInvoicesForPayment,
            onUpdateSelectedInvoices: onUpdateSelectedInvoices,
            filteredInvoices: filteredInvoices,
            setSelectedBilledInvoices: setSelectedBilledInvoices,
            selectedBilledInvoices: selectedBilledInvoices
        },
        makePaymentSourceComponentId: {
            title: messages.setPaymentSource,
            xCenter: 'bc',
            model: paymentInstrument,
            isDisabled: disablePayNow(),
            isSelectedInvoiceAmount: manualAmountToPay
                && isAmountToPayValid(manualAmountToPay.amount)
        },
        makePaymentAmountToPayId: {
            defaultCurrency: currency,
            disabled: selectedInvoicesForPayment.length === 0,
            onValueChange: updateManualAmount
        },
        makePaymentConfirmationContainerId: {
            visible: selectedScreen === 'paymentConfirmation'
        },
        makePaymentConfirmationMessageId: {
            content: translator(messages.paymentOfHasBeenAppliedToYourAccount, {
                paidAmount: intl.formatNumber(
                    payingAmountValue,
                    {
                        style: 'currency',
                        currency: currency,
                        currencyDisplay: 'code'
                    }
                )
            })
        },
        setUpAutomaticPaymentContainerId: {
            visible: selectedScreen === 'automatic'
        },
        setupAutomaticPaymentComponentId: {
            xCenter: 'bc',
            model: paymentInstrument
        },
        setUpAutomaticPaymentConfirmationContainerId: {
            visible: selectedScreen === 'automaticConfirmation'
        },
        setUpAutomaticPaymentConfirmationInfoId: {
            content: translator(messages.yourAutomaticPaymentsWillBeginOn, {
                startingDate: intl.formatDate(new Date(firstInvoiceDueDate), { year: 'numeric', month: 'short', day: 'numeric' })
            })
        },
        changePaymentSourceContainerId: {
            visible: selectedScreen === 'payment-source'
        },
        changePaymentSourceFormComponentId: {
            title: messages.changePaymentMethod,
            xCenter: 'bc',
            model: paymentInstrument
        },
        changePaymentSourceConfirmationContainerId: {
            visible: selectedScreen === 'paymentSourceConfirmation'
        },
        policyListContainerId: {
            visible: selectedScreen === 'policy-list'
        },
        viewPoliciesComponentId: {
            policySummaries: policySummaries,
            policyNumbers: relatedPolicies
        },
        amountToPayErrorMessageId: {
            content: translator(messages.amountToPayErrorMessage, {
                amount: intl.formatNumber(
                    selectedInvoiceAmount,
                    {
                        style: 'currency',
                        currency: currency,
                        currencyDisplay: 'code'
                    }
                )
            }),
            visible: manualAmountToPay != null && selectedBilledInvoices.length === 0
                && (manualAmountToPay.amount < selectedInvoiceAmount
                || checkInvoicesSelected())
        },
        leastAmountToPayErrorMessageId: {
            content: translator(messages.leastAmountToPayErrorMessage, {
                amount: intl.formatNumber(
                    selectedInvoiceAmount,
                    {
                        style: 'currency',
                        currency: currency,
                        currencyDisplay: 'code'
                    }
                )
            }),
            visible: manualAmountToPay != null && selectedBilledInvoices.length > 0
                && manualAmountToPay.amount < selectedInvoiceAmount
        },
        billingSummaryPaymentTnCId: {
            value: isTnCSelected
        },
        pendingCancellationwarning: {
            visible: showPendingCancellationMessage
        },
        disputedAmountNotification: {
            message: translator(messages.policyDisputedAmountWarningMessage, {
                amount: intl.formatNumber(
                    disputedAmount ? disputedAmount.amount : 0,
                    {
                        style: 'currency',
                        currency: currency,
                    }
                ),
                currentDate: intl.formatDate(new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })), { year: 'numeric', month: 'short', day: 'numeric' })
            }),
            visible: disputedAmount && disputedAmount.amount > 0
        },
        convertedPolicyWarning: {
            visible: selectedPolicyDetails !== undefined
            && selectedPolicyDetails[0]?.isConvertRenewal
        },
        renewalPolicyWarning: {
            visible: selectedPolicyDetails !== undefined
            && selectedPolicyDetails[0]?.hasRenewingTransaction,
        }
    };

    const dataForComponent = {
        invoiceStreamId,
        selectedInvoiceFilter,
        periodicity,
        startingDate: firstInvoiceDueDate,
        manualAmountToPay
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            paymentpagecomponent: PaymentComponent
        },
        resolveCallbackMap: {
            onClickMenuLink,
            onClickConfirmMakePayment,
            onClickCancelMakePayment,
            onClickConfirmSetupAutomaticPayment,
            onClickCancelSetupAutomaticPayment,
            onClickConfirmChangePaymentSource,
            onClickCancelChangePaymentSource,
            onTriggerFunction: triggerModal,
            handleTnCSelection
        }
    };

    return (
        <MetadataForm
            data={dataForComponent}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            isUsingNewValidation
        />
    );
}

BillingSummary.propTypes = {
    invoiceStreamId: PropTypes.string,
    accountLevelBilling: PropTypes.bool,
    lobCode: PropTypes.string,
    paymentGroupDetails: PropTypes.shape({
        invoiceSummary: PropTypes.array,
        relatedPolicies: PropTypes.array,
        paymentInstrument: PropTypes.object,
        paymentInstrumentSummary: PropTypes.object,
        periodicity: PropTypes.string
    }),
    currency: PropTypes.string.isRequired,
    policySummaries: PropTypes.arrayOf(PropTypes.object),
    authHeader: PropTypes.shape({}).isRequired,
    reloadInvoiceDetails: PropTypes.func.isRequired,
    policyNumber: PropTypes.number,
    updateShowPayeezyIframe: PropTypes.func.isRequired
};

BillingSummary.defaultProps = {
    invoiceStreamId: null,
    policyNumber: null,
    accountLevelBilling: null,
    lobCode: null,
    paymentGroupDetails: {
        invoiceSummary: [],
        relatedPolicies: [],
        paymentInstrument: null,
        paymentInstrumentSummary: null,
        periodicity: null
    },
    policySummaries: []
};

export default withAuthenticationContext(withModalContext(BillingSummary));
