import { defineMessages } from 'react-intl';

export default defineMessages({
    sectionTitle: {
        id: 'policy.common.component.premium.Premium Details',
        defaultMessage: 'Premium Details'
    },
    standardPremiumTitle: {
        id: 'policy.common.component.premium.Standard Premium',
        defaultMessage: 'Standard Premium'
    },
    otherPremiumTitle: {
        id: 'policy.common.component.premium.Other Premium',
        defaultMessage: 'Other Premium and Surcharges'
    },
    premiumLoc: {
        id: 'policy.common.component.premiums.LocationId',
        defaultMessage: 'Loc.'
    },
    premiumCode: {
        id: 'policy.common.component.premiums.Code',
        defaultMessage: 'Code'
    },
    premiumDescription: {
        id: 'policy.common.component.premiums.Description',
        defaultMessage: 'Description'
    },
    premiumExposure: {
        id: 'policy.common.component.premiums.Exposure',
        defaultMessage: 'Exposure'
    },
    premiumRate: {
        id: 'policy.common.component.premiums.Rate',
        defaultMessage: 'Rate'
    },
    premiumAmount: {
        id: 'policy.common.component.premiums.Amount',
        defaultMessage: 'Amount'
    }
});
