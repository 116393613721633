import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class RepairFacilityService {
    static getVendorsNearby(params, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vendor'),
            'proximitySearch',
            [params],
            additionalHeaders
        );
    }
}
