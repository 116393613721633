import { defineMessages } from 'react-intl';

export default defineMessages({
    removeDocument: {
        id: 'policy.common.component.documents.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmRemoveDocument: {
        id: 'policy.common.component.documents.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove'
    },
    documentRemoveConfirmYes: {
        id: 'policy.common.component.documents.Yes',
        defaultMessage: 'Yes'
    },
    documentRemoveConfirmNo: {
        id: 'policy.common.component.documents.No',
        defaultMessage: 'No'
    },
    documentDeletionFailed: {
        id: 'policy.common.component.documents.Document deletion failed',
        defaultMessage: 'Document deletion failed'
    },
    documentCannotDeleted: {
        id: 'policy.common.component.documents.Sorry your document could not be deleted at this time',
        defaultMessage: 'Sorry your document could not be deleted at this time'
    },
    documentDateUploaded: {
        id: 'policy.common.component.documents.Date Uploaded',
        defaultMessage: 'Date Uploaded'
    },
    documentUploadFailed: {
        id: 'policy.common.component.documents.Document upload failed',
        defaultMessage: 'Document upload failed'
    },
    documentCannotUploaded: {
        id: 'policy.common.component.documents.Failed to upload file, either you do not have permission or a file exists with the same name.',
        defaultMessage: 'Failed to upload file, either you do not have permission or a file exists with the same name.'
    },
    noDocumentFound: {
        id: 'policy.common.component.documents.No Documents Found.',
        defaultMessage: 'No Documents Found.'
    },
     documentUploadTooltip: {
        id: 'policy.common.component.documents.Documents Upload Tooltip Message.',
        defaultMessage: 'Volunteer Fire Fighter and/or Volunteer Ambulance rosters only. Any other documents uploaded will not be viewed and should be submitted by way of resource email accounts to the SWIF Policy Support department. Please visit https://www.dli.pa.gov/Businesses/swif for more details.'
    },
    uploadedDocuments: {
        id: 'policy.common.component.documents.Upload Documents',
        defaultMessage: 'Uploaded Documents'
    },
    policyDocuments: {
        id: 'policy.common.component.documents.Policy Document',
        defaultMessage: 'Policy Documents'
    },
    okText: {
        id: 'policy.common.component.documents.Okay Text',
        defaultMessage: 'Ok'
    }
});
