import { defineMessages } from 'react-intl';

export default defineMessages({
    accountSummary: {
        id: 'portal.views.home-page-layout.Account Summary',
        defaultMessage: 'Account Summary',
    },
    myBalance: {
        id: 'portal.directives.templates.billing-summary-tiles.My Balance',
        defaultMessage: 'My Balance',
    },
    overdue: {
        id: 'portal.directives.templates.billing-summary-tiles.Overdue',
        defaultMessage: 'Overdue',
    },
    payNow: {
        id: 'portal.directives.templates.billing-summary-tiles.Pay Now',
        defaultMessage: 'Pay Now',
    },
    myNextBill: {
        id: 'portal.directives.templates.billing-summary-tiles.My Next Bill',
        defaultMessage: 'My Next Bill'
    },
    due: {
        id: 'portal.directives.templates.billing-summary-tiles.Due',
        defaultMessage: 'Due',
    },
    updateMyDetails: {
        id: 'portal.views.home-page-layout.Update My Details',
        defaultMessage: 'Update My Details'
    },
    lastPayment: {
        id: 'portal.directives.templates.billing-summary-tiles.My Last Payment',
        defaultMessage: 'My Last Payment',
    },
    paid: {
        id: 'portal.directives.templates.billing-summary-tiles.Paid',
        defaultMessage: 'Paid',
    },
    renewalInfo: {
        id: 'portal.views.home-page-layout.You have policies due for renewal.',
        defaultMessage: 'You have policies due for renewal.'
    },
    pendingCancellationWarning: {
        id: 'portal.views.home-page-layout.This policy is pending cancellation',
        defaultMessage: 'This policy is pending cancellation'
    },
    policies: {
        id: 'portal.views.home-page-layout.Policies',
        defaultMessage: 'Policies'
    },
    type: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.type',
        defaultMessage: 'Type'
    },
    statusActive: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.Active',
        defaultMessage: 'Active'
    },
    statusRenewed: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.Renewed',
        defaultMessage: 'Renewed'
    },
    status: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.status',
        defaultMessage: 'Status'
    },
    statusPendingCancel: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.pendingCancel',
        defaultMessage: 'Pending Cancel'
    },
    statusCancelled: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.cancelled',
        defaultMessage: 'Cancelled'
    },
    policy: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.policy',
        defaultMessage: 'Policy'
    },
    effective: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.effective',
        defaultMessage: 'Effective Period'
    },
    premium: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.premium',
        defaultMessage: 'Premium'
    },
    myQuickLinks: {
        id: 'portal.views.home-page-layout.My Quick Links',
        defaultMessage: 'My Quick Links'
    },
    printanIDCard: {
        id: 'portal.views.home-page-layout.Print an ID Card',
        defaultMessage: 'Print an ID Card'
    },
    makeAPayment: {
        id: 'portal.views.home-page-layout.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    setupAutomaticPayments: {
        id: 'portal.views.home-page-layout.Setup Automatic Payments',
        defaultMessage: 'Setup Automatic Payments'
    },
    changeMyPolicy: {
        id: 'portal.views.home-page-layout.ChangeMyPolicy',
        defaultMessage: 'Change My Policy'
    },
    fileClaim: {
        id: 'portal.views.home-page-layout.FileClaim',
        defaultMessage: 'File a Claim'
    },
    getAPersonalAutoQuote: {
        id: 'portal.views.home-page-layout.Get a Personal Auto Quote',
        defaultMessage: 'Get a Personal Auto Quote'
    },
    getAHomeOwnersQuote: {
        id: 'portal.views.home-page-layout.Get a Home Owners Quote',
        defaultMessage: 'Get a Home Owners Quote'
    },
    policyDetailsAgent: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.agent',
        defaultMessage: 'Agent'
    },
    policyDetailsTel: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.tel',
        defaultMessage: 'T.'
    },
    policyDetailsIdCard: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.id card',
        defaultMessage: 'ID Card'
    },
    policyDetailsFileClaim: {
        id: 'policy.common.directives.templates.policy-account-policy-summaries.fileClaim',
        defaultMessage: 'File a Claim'
    },
    myBalanceTileCurrent: {
        id: 'portal.directives.templates.billing-summary-tiles.Current',
        defaultMessage: 'Current'
    },
    policyNotShowing: {
        id: 'portal.views.home-page-layout.Do you have a policy with us but it is not showing?',
        defaultMessage: 'Do you have a policy with us but it is not showing?'
    },
    addPolicy: {
        id: 'portal.views.home-page-layout.Add Policy',
        defaultMessage: 'Add Policy'
    },
    pendingAudit: {
        id: 'portal.views.home-page-layout.Pending Audit',
        defaultMessage: 'Pending Audit'
    },
    cancelledAmountWarning: {
        id: 'portal.views.home-page-layout.Cancelled Policy Warning',
        defaultMessage: 'This account has at least one policy with an audit in pending status. To make a payment, please contact SWIF Collections Department at 570-941-1678 or email ra-liswifcollect@pa.gov with you contact information for a return call to process your payment. Payments on active/pending cancel policies can still be processed through the Billing tab.'
    },
    totalPaid: {
        id: 'portal.directives.templates.billing-summary-tiles.Total Paid',
        defaultMessage: 'Total Paid',
    },
    credit: {
        id: 'portal.directives.templates.billing-summary-tiles.Credit Amount',
        defaultMessage: 'Credit',
    },
    totalDisputedAmountWarningMessage: {
        id: 'billing.billing-summary.Total Disputed Amount Warning Message',
        defaultMessage: 'This account has {amount} in dispute as of {currentDate} which is not reflected in the totals shown. Please review Billing tab for additional details.'
    }
});
