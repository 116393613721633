import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@jutro/components';
import styles from './RepairFacilityRating.module.scss';

const MAX_RATE = 5;
class RepairFacilitiesRating extends Component {
    static propTypes = {
        score: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            // range is not inclusive plus 1.
            // it will return values range 1 to 5 in an array ex: [1, 2, 3, 4, 5]
            starsRange: _.range(1, MAX_RATE + 1)
        };
    }

    rating = (score) => {
        return score ? (score / 100.0) * MAX_RATE : 0.0;
    };

    getNumericRate = (score) => {
        return this.rating(score).toFixed(1);
    };

    isStarActive = (score, index) => {
        const rating = this.rating(score);
        return index <= Math.round(rating) && rating > 0;
    };

    iconClassName = (score, index) => {
        const iconClassName = classNames(styles.starIcon, {
            [styles.activeStar]: this.isStarActive(score, index + 1)
        });
        return <Icon className={iconClassName} icon="mi-star" />;
    };

    numericRate = (score) => {
        return score > 0 ? styles.numericRate : styles.noNumericRate;
    };

    render() {
        const { starsRange } = this.state;
        const { score } = this.props;
        return (
            <div className={styles.rating}>
                <div className={this.numericRate(score)}>{this.getNumericRate(score)}</div>
                <div className={styles.starRate}>
                    {starsRange.map((val, index) => this.iconClassName(score, index))}
                </div>
            </div>
        );
    }
}

export default RepairFacilitiesRating;
