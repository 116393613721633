import { defineMessages } from 'react-intl';

export default defineMessages({
    coverage: {
        id: 'policycommon.component.vehicle-coverage.Coverage',
        defaultMessage: 'Coverage'
    },
    type: {
        id: 'policycommon.component.vehicle-coverage.Type',
        defaultMessage: 'Type'
    },
    limits: {
        id: 'policycommon.component.vehicle-coverage.Limits',
        defaultMessage: 'Limits'
    },
    deductible: {
        id: 'policycommon.component.vehicle-coverage.Deductible',
        defaultMessage: 'Deductible'
    },
    premiumBreakdown: {
        id: 'policycommon.component.vehicle-coverage.Premium Breakdown',
        defaultMessage: 'Premium Breakdown'
    },
});
