import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CurrencyField } from '@jutro/components';
import metadata from './PolicyPremiumDetails.metadata.json5';
import styles from './PolicyPremiumDetails.module.scss';
import './PolicyPremiumDetails.messages';

const getCell = (items, index, property) => {
    return !_.isObject(items[property.id]) ? items[property.id] : null;
};

const renderFormattedCurrencyField = (item, loc) => {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${loc}`}
                value={item}
                readOnly
                hideLabel
                className={styles.currencyNormal}
            />
        </div>
    );
};

const getFormattedCurrency = (item, index, property) => {
    return renderFormattedCurrencyField(item[property.id], item.premiumLoc);
};

function PolicyPremiumDetails(props) {
    const { value: policyPremiums } = props;

    const getStandardPremiumData = useCallback((data) => {
        return data
            .map((premium) => {
                return {
                    premiumLoc: premium.locationId,
                    premiumCode: premium.code,
                    premiumDescription: premium.description,
                    premiumExposure: premium.basis,
                    premiumRate: premium.rate,
                    premiumAmount: premium.amount
                };
            })
            .sort((a, b) => {
                return a.name - b.name;
            });
    }, [policyPremiums]);

    const getOtherPremiumData = useCallback(() => {
        return policyPremiums.otherPremiumAndSurcharges
            .map((premium) => {
                return {
                    premiumLoc: premium.locationId,
                    premiumCode: premium.code,
                    premiumDescription: premium.description,
                    premiumExposure: premium.basis,
                    premiumRate: premium.rate,
                    premiumAmount: premium.amount
                };
            })
            .sort((a, b) => {
                return a.name - b.name;
            });
    }, [policyPremiums]);

    const generatePolicyPremiumOverrides = useCallback(() => {
        const standardPremiumList = _.get(policyPremiums, 'standardPremiumList', []);
        const overrides = standardPremiumList.map((standardPremium, index) => {
            return {
                [`standardPremiumDetailsTable${index}`]: {
                    data: getStandardPremiumData(standardPremium.standardPremiumDetails)
                },
                [`standardPremiumHeader${index}`]: {
                    content: standardPremium.ratingPeriodDescription
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [policyPremiums, getStandardPremiumData]);

    const overrides = {
        '@element': {
            textAlign: 'left'
        },
        otherPremiumDetailsTable: {
            data: getOtherPremiumData()
        },
        ...generatePolicyPremiumOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            getPremiumData: getCell,
            getFormattedCurrency
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                policyPremiums,
                id,
                path,
                overrides
            );
        },
        [policyPremiums, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyPremiums}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PolicyPremiumDetails.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

PolicyPremiumDetails.defaultProps = {
    value: []
};

export default PolicyPremiumDetails;
