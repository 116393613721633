import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'policy.view.policy-details.Policy Details',
        defaultMessage: 'Policy Details: {policyNumber}'
    },
    currentTab: {
        id: 'policy.view.policy-details.tab.Current',
        defaultMessage: 'Current'
    },
    changePolicy: {
        id: 'policy.view.policy-details.Change Policy',
        defaultMessage: 'Change Policy'
    },
    renewalTab: {
        id: 'policy.view.policy-details.tab.Renewing',
        defaultMessage: 'Renewing'
    },
    DownloadIDCard: {
        id: 'policy.view.policy-details.Download ID Card',
        defaultMessage: 'Download ID Card'
    },
    payrollReportMessage: {
        id: 'policy.view.policy-details.Payroll Report Due',
        defaultMessage: 'A payroll report for {month} is due on {dueDate}. Click below to request that a blank copy of this payroll report be emailed to you.'
    },
    requestPayrollReport: {
        id: 'policy.view.policy-details.Request Payroll Report',
        defaultMessage: 'Request Payroll Report'
    },
    payrollActivitySuccessMessage: {
        id: 'policy.view.policy-details.Payroll Activity Success Message',
        defaultMessage: 'Your request to receive your monthly payroll report form has been received and is being processed.  Please watch for it in your email inbox.'
    },
    successText: {
        id: 'policy.view.policy-details.Success Text',
        defaultMessage: 'Success'
    },
    payrollActivityFailureMessage: {
        id: 'policy.view.policy-details.Payroll Activity Failure Message',
        defaultMessage: 'Unable to complete request. Please contact our Customer Service Department at 570-963-4635, or email your request to ra-liswif-prl-rpts@pa.gov'
    },
    failureText: {
        id: 'policy.view.policy-details.Failure Text',
        defaultMessage: 'Failure'
    },
    okText: {
        id: 'policy.view.policy-details.Okay Text',
        defaultMessage: 'Ok'
    }
});
