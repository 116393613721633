import React, { Component } from 'react';
import { Icon } from '@jutro/components';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import messages from './RepairFacilitiesSearch.messages';
import styles from './RepairFacilitiesSearch.module.scss';

class RepairFacilitiesSearchResults extends Component {
    static propTypes = {
        selectAddress: PropTypes.func.isRequired,
        myLocationHandler: PropTypes.func.isRequired,
        myLocation: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number
        }),
        policyIncidentAddress: PropTypes.func.isRequired,
        places: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    };

    static defaultProps = {
        myLocation: {
            latitude: null,
            longitude: null
        }
    };

    defaultDropdownOptions() {
        const { myLocation, myLocationHandler, policyIncidentAddress } = this.props;

        return [
            {
                type: 'policy',
                label: messages.policyAddress,
                handler: policyIncidentAddress,
                icon: 'mi-home',
                enable: true
            },
            {
                type: 'incident',
                label: messages.incident,
                handler: policyIncidentAddress,
                icon: 'mi-directions_car',
                enable: true
            },
            {
                type: 'myloation',
                label: messages.myLocation,
                handler: myLocationHandler,
                icon: 'mi-person',
                enable: myLocation
            }
        ];
    }

    render() {
        const { selectAddress, places } = this.props;
        const options = this.defaultDropdownOptions();
        return (
            <TranslatorContext.Consumer>
                {(translator) => {
                    return (
                        <div className={styles.dropdown}>
                            {places.map((place) => {
                                return (
                                    <div className={styles.placeList} key={place.id}>
                                        <div
                                            className={styles.place}
                                            onClick={selectAddress}
                                            onKeyPress={() => {}}
                                            role="button"
                                            tabIndex="0"
                                        >
                                            {place.description}
                                        </div>
                                    </div>
                                );
                            })}
                            <div className={styles.placeList}>
                                {options.map((item) => {
                                    return (
                                        <div className={styles.placeList}>
                                            <div
                                                className={styles.place}
                                                onClick={item.handler}
                                                onKeyPress={() => {}}
                                                role="button"
                                                tabIndex="0"
                                            >
                                                <div className={styles.option}>
                                                    <div className={styles[item.type]}>
                                                        <Icon className="icon" icon={item.icon} />
                                                    </div>
                                                    <span className={styles.label}>
                                                        {translator(item.label)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    }
}

export default RepairFacilitiesSearchResults;
