export default {
    wrench: {
        icon: 'wrench',
        backgroundColor: '#32AADC'
    },
    home: {
        icon: 'home',
        backgroundColor: '#976190'
    },
    car: {
        icon: 'car',
        backgroundColor: '#ef2f39'
    },
    male: {
        icon: 'male',
        backgroundColor: '#50c7a7'
    }
};
